import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { baseUrl } from 'src/app/config/env-config';
import { FormBuilder, Validators } from '@angular/forms';
import { RosterDetailsService } from 'src/app/services/roster-details.service';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';

@Component({
  selector: 'shift-end',
  templateUrl: './shift-end.component.html',
  styleUrls: ['./shift-end.component.scss']
})
export class ShiftEndComponent implements OnInit {
  @Input() data: any;
  @Input() nextSch: any;
  @Output() modalClosed = new EventEmitter();
  
  showModal: boolean;
  registerForm: FormGroup;
  bindData;
  allvehiclesList;
  driverList;
  helperList;
  fulldata;
  selectedDriver=null;
  selectedHelper=null;

  constructor(public cs:CommonService,private rosterdetailsservice: RosterDetailsService,private formBuilder: FormBuilder,private http: HttpClient) {
    
    this.registerForm = this.formBuilder.group({
      scheduledates:['', [Validators.required]],
      totime: ['', [Validators.required]],
      fromtime: ['', [Validators.required]],
      drivername:['',[Validators.required]],
      helpername:['',[Validators.required]]
  });


   }

  ngOnInit(): void {
    this.showModal = false;
  }

  ngOnChanges(changes: SimpleChanges) {
      console.log("shftEnd",this.data.vehicleDetails.id)
      //this.nextSch
      //this.nxtSchdules[dashindx].sort((a, b)=> a.fromTime - b.fromTime );
      this.http.get(baseUrl+"api/v1/scheduler/vehicle/"+this.data.vehicleDetails.id).subscribe(res=>{
        this.fulldata = res['data'];
          console.log("shift-end",res)
          this.bindData = (res['data'].sort((a, b)=> a.fromTime - b.fromTime ))[0];
      })
      this.getAllVehiclesList();
      this.getAllDriverList();
  }

  show()
  { 
 
    console.log(this.bindData)

      this.registerForm.controls['scheduledates'].setValue( moment(this.bindData['scheduleDate']).format('YYYY-MM-DD') );
      this.registerForm.controls['totime'].setValue( moment(this.bindData['slotList'][0]['toTime']).format('HH:mm') );
      this.registerForm.controls['fromtime'].setValue( moment(this.bindData['slotList'][0]['fromTime']).format('HH:mm') );
      this.registerForm.controls['drivername'].setValue( this.bindData['slotList'][0]['driverId'] );
      this.selectedDriver = this.bindData['slotList'][0]['driverId'];
      this.registerForm.controls['helpername'].setValue( this.bindData['slotList'][0]['helperId'] );
      this.selectedHelper = this.bindData['slotList'][0]['helperId'];


    this.showModal = true; 

  }

  hide()
  {
    this.showModal = false;
    this.modalClosed.emit({});
  }

  getAllVehiclesList() //get All Vehicles list
  {
    this.rosterdetailsservice.getAllVehicles().subscribe(data =>{
     //console.log("getallvehiclelist",data['data']);
     this.allvehiclesList = [];
     this.allvehiclesList = (data['data']);
     //console.log("allvehicleList",this.allvehiclesList);
    });
  }


  getAllDriverList() // get All Driver/Helper Details 
  {
   this.rosterdetailsservice.getAllDriverlist().subscribe(response =>{
   //console.log("getAllDriverStaff",response['data']);
   this.driverList = [];
   this.driverList = (response['data']).filter(m=>m.userType =="Driver");
   //console.log("driverList",this.driverList)
   this.helperList = [];
   this.helperList = (response['data']).filter(m=>m.userType =="Helper");
   //console.log("helperList",this.helperList)
    });
  }

  onDChange() // Driver list Dropdown
  {
  }

onHChange() // Helper list Dropdown
  {
  }

  onSubmit() 
  {
    let ffrmstr = String(this.registerForm.value.scheduledates) +" " + this.registerForm.value.fromtime;
    let ttostr = String(this.registerForm.value.scheduledates) +" " + this.registerForm.value.totime;
    
      let updateRecordBody={
         

        "vehicleId": this.bindData['vehicleId'],
        "id": this.bindData['id'],
        "scheduleDate" :moment(this.registerForm.value.scheduledates).toDate().getTime(),
        "slotList": [
            {
                "fromTime":moment(ffrmstr,'YYYY-MM-DD HH:mm').toDate().getTime(),
                "toTime":moment(ttostr,'YYYY-MM-DD HH:mm').toDate().getTime() ,
                "driverId": this.selectedDriver,
                "helperId":this.selectedHelper ,
                "tripId": "",
                // "scheduleDate":moment(this.registerForm.value.scheduledates).toDate().getTime(),
                "driverName":this.driverList.filter(d=> d.id == this.selectedDriver)[0]['name'],
                "helperName":this.helperList.filter(d=> d.id == this.selectedHelper)[0]['name']
            }         
        ]
      }

   
      this.http.post(baseUrl+"api/v2/roaster/roasterItems/update",updateRecordBody).subscribe(data =>{ 
        //console.log("updateRecord")
      });     
     this.hide();
    
  }



}
