import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clear-input',
  templateUrl: './clear-input.component.html',
  styleUrls: ['./clear-input.component.css']
})
export class ClearInputComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
