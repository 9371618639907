import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthServiceService } from '../../../services/auth-service.service';
import { from } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import Pusher from 'pusher-js';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  username = '';
  password = '';
  loginDetails;
  pusher: any = "";


  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private authService: AuthServiceService,
    private CommonService: CommonService,
    private toastr: ToastrService
  ) {
    console.log(this.authService, "auth");

    if (this.authService.loggedIn) {
      let userrole = JSON.parse(localStorage.getItem('currentUser'))['data']['roles']
      if (userrole.toString() === 'ACCOUNTANT') {
        this.redirect('/records');
      } else {
        this.redirect('/roaster');
      }
    }

    Pusher.logToConsole = true;

    var pusher = new Pusher('45987be4c79e14071e28', {
      cluster: 'ap2'
    });

    var channel = pusher.subscribe('Login');
    channel.bind('HR', function (data) {
      // alert(JSON.stringify(data));
      console.log('pusher');
    });
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });


  }
  get f() {
    return this.registerForm.controls;
  }

  async onSubmit() {
    this.submitted = true;
    this.loginDetails = JSON.stringify(this.registerForm.value);

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    } else if (this.username.length > 0) {
      if (this.password && this.password.length > 0) {
        this.username = this.username.trim();
        this.password = this.password.trim();
        this.authService.login(this.username, this.password).subscribe(
          (data) => {
            console.log(data, "log data");
            localStorage.setItem('username', this.username);
            this.username = '';
            this.password = '';
            localStorage.setItem('currentUser', JSON.stringify(data));
            this.CommonService.getMasters();
            this.toastr.success('Login Successfully!');
            console.log(data['data']['roles'].toString(), 'log data');
            if (data['data']['roles'].toString() === 'ACCOUNTANT') {
              this.redirect('/records');
            } else {
              this.redirect('/roaster');
            }
          },
          (error) => {
            if (error.status == 400) {
              alert('Invalid Username and Password');
              this.username = '';
              this.password = '';
            }
          }
        );
      }
    }
  }
  redirect(path) {
    this.router.navigate([path]);
  }
}
