import { Component, Input, OnInit } from '@angular/core';
import { CardDetailsManagerService } from 'src/app/services/card-details-manager.service';
import { HttpClient } from '@angular/common/http';
import{baseUrl} from'../../../../config/env-config';

@Component({
  selector: 'edit-records',
  templateUrl: './edit-records.component.html',
  styleUrls: ['./edit-records.component.scss']
})
export class EditRecordsComponent implements OnInit {

  @Input('formtype') formtype: string;
  @Input('dataa') dataa: any;
  loadingnow=false;
 
  constructor(public cdman:CardDetailsManagerService,public http:HttpClient) {  
    
  }

  ngOnInit(): void {
    console.log('dataa123', this.dataa);
  }

  closeCard()
  {
    this.cdman.dismiss('editRecords');
  }

  formSubmitt()
  {
    if(this.formtype == 'a')
    {
      console.log(this.dataa)
      this.loadingnow=true;
      console.log(this.dataa)
      let driverBody =this.dataa;
      this.http.post(baseUrl+"api/v1/driver/update/"+ this.dataa.id,driverBody)
      .subscribe(response =>{
        console.log("RESPONSE",response);
        this.cdman.dismiss('editRecords');
        this.loadingnow=false;
      })
    }
    else if(this.formtype == 'b')
    {
      this.loadingnow=true;
      console.log(this.dataa)
      let vehicleBody =this.dataa;
      this.http.post(baseUrl+"api/v1/vehicle/update",vehicleBody)
      .subscribe(response =>{
        console.log("RESPONSE",response);
        this.cdman.dismiss('editRecords');
        this.loadingnow=false;

      })
      
    }
   
  }

}
