import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CardDetailsManagerService {

  private state = {
    problemDetails:false,
    fuelDetails:false,
    editRecords:false,
    bcDetails:false
  }
  private data = {};
  private dismissData = {}
  constructor() { }

  setActive(key,data)
  {
    this.state[key] = true;
    this.data = data;
  }

  getModalData()
  {
    return this.data;
  }

  dismiss(key)
  {
    this.state[key] = false;
    this.data = {};
  }

}
