import { Component, OnInit } from '@angular/core';
import { DashObj, DashObjAdapter } from '../../../models/dashobj.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CardDetailsManagerService } from 'src/app/services/card-details-manager.service';
import { baseUrl } from 'src/app/config/env-config';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { fileUploadUrl } from '../../../config/env-config';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss'],
})
export class DashComponent implements OnInit {
  dashItemList = [];
  dashItemsList = [];
  dashItemListCount;
  fixNeededCount;
  currCardfilter;
  currfilter;
  vendor = null;
  fileData = null;
  vendorCostEstimation = null;
  vendorTimeEstimation = null;
  u: any;
  startDate;
  endDate;
  nxtSchdules = [];
  expenseList = [];
  allVendorList = [];
  assignVendor = false;
  tripDetails = false;
  selectedVandorInfo = '';
  selectedTripInfo = '';
  problem = '';
  vechileNo = '';
  constructor(
    private http: HttpClient,
    private adapter: DashObjAdapter,
    public cdman: CardDetailsManagerService,
    public cs: CommonService
  ) {}

  ngOnInit(): void {
    this.getDashItemsList();
    setInterval(() => {
      this.getDashItemsList();
    }, 60000);
    //this.getDashItemsList();
    this.getVendor();
  }

  getDashItemsList() {
    this.http
      .get(
        baseUrl +
          'api/v1/VehicleActionHistory/getVehicleHistoryByVehicleID/helpnow',
        { headers: this.cs.getHeaders() }
      )
      .subscribe(
        (res) => {
          this.dashItemsList = res['data'];
          this.cs.setCommValue('dashCount', this.dashItemsList.length);
        },
        (error) => {
          this.cs.autoLogout(error);
        }
      );
  }

  setCardFilter(filterName) {
    if (this.currCardfilter == filterName) {
      this.currCardfilter = undefined;
    } else {
      this.currCardfilter = filterName;
    }
    this.getFilterConditon();
  }

  getFilterConditon() {
    this.currfilter = '';
    if (this.currCardfilter == 'action') {
      this.currfilter = 'breakdown';
    } else if (this.currCardfilter == 'trip') {
      this.currfilter = 'trip';
    } else if (this.currCardfilter == 'brex') {
      this.currfilter = 'expense';
    }
  }

  getVendor() {
    return this.http
      .get(baseUrl + 'api/v1/vendor/getByVendorType/breakdownvendor', {
        headers: this.cs.getHeaders(),
      })
      .subscribe(
        (res) => {
          this.allVendorList = res['data'];
          console.log(this.allVendorList,"Riyaz");
          
        },
        (error) => {
          this.cs.autoLogout(error);
        }
      );
  }

  openDetailView(a) {
    this.cdman.setActive('fuelDetails', { data: a });
  }

  showimgs(unique) {
    //let unique = [...new Set(a.expenseDocumentList)];
    //console.log(unique)

    unique.forEach((u) => {
      window.open(u.toString());
    });
  }

  hidePopup() {
    this.assignVendor = false;
    this.tripDetails = false;
  }
  showPopup(info, historyInfo) {
    console.log(info),"Dash info";
    this.vechileNo = info;
    this.selectedVandorInfo = historyInfo;
    this.problem = historyInfo.newData.problem;
    this.assignVendor = true;
  }

  showTripPopup(info, historyInfo) {
    this.vechileNo = info;
    this.selectedTripInfo = historyInfo;
    this.tripDetails = true;
    //console.log(historyInfo);
  }

  onFileSelect(event) {
    this.fileData = null;
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0];
    }
  }
  assignVendorFun() {
    var error = false;
    var formInfo = { breakDownStatus: 'vendorassigned' };

    if (this.selectedVandorInfo) {
      formInfo['transactionId'] =
        this.selectedVandorInfo['newData']['transactionId'];
    }

    if (
      this.selectedVandorInfo['newData']['breakDownStatus'].toLowerCase() ==
      'pending'
    ) {
      var vendorInfo = this.getVendorInfoById(this.vendor);
      if (
        vendorInfo &&
        this.vendorCostEstimation &&
        this.vendorTimeEstimation
      ) {
        vendorInfo['vendorCostEstimation'] = this.vendorCostEstimation;
        vendorInfo['vendorTimeEstimation'] = this.vendorTimeEstimation;
        formInfo['vendorDetails'] = vendorInfo;
      } else {
        error = true;
      }
    } else {
      formInfo['resolved'] = true;
      formInfo['breakDownStatus'] = 'completed';
    }

    if (this.fileData) {
      const formData = new FormData();
      formData.append('file', this.fileData);
      formData.append('serviceName', 'DriverApp');
      formData.append('userID', this.selectedVandorInfo['newData']['driverId']);
      // this.http.post("https://file-service.prod.gethelpnow.in/api/v1/file/upload",formData,{ headers: this.cs.getHeaders() })
      //file upload url
      this.http
        .post(fileUploadUrl, formData, { headers: this.cs.getHeaders() })
        .subscribe(
          (res) => {
            var response = res['data'].url;
            var gstBillDocumentList = [response];
            formInfo['gstBillDocumentList'] = gstBillDocumentList;
            this.postData(formInfo, error);
            this.fileData = '';
          },
          (error) => {
            this.cs.autoLogout(error);
          }
        );
    } else {
      if (
        this.selectedVandorInfo['newData']['gstBillDocumentList'].length == 0 &&
        this.selectedVandorInfo['newData']['breakDownStatus'].toLowerCase() !=
          'pending'
      ) {
        error = true;
      }
      this.postData(formInfo, error);
    }
  }

  postData(formInfo: any, error: any) {
    if (error) {
      alert('Please fill required data.');
      return false;
    }
    this.http
      .post(baseUrl + 'api/v1/breakdown/updateByTransactionId', formInfo, {
        headers: this.cs.getHeaders(),
      })
      .subscribe(
        (res) => {
          var response = res['data'];
          this.assignVendor = false;
          this.getDashItemsList();
          this.vendorCostEstimation = '';
          this.vendorTimeEstimation = '';
        },
        (error) => {
          this.cs.autoLogout(error);
        }
      );
  }

  getVendorInfoById(id) {
    var vendorInfo = '';
    this.allVendorList.forEach((vendor, index) => {
      if (vendor.id == id) {
        vendorInfo = vendor;
      }
    });
    return vendorInfo;
  }
}
