
import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { CardDetailsManagerService } from 'src/app/services/card-details-manager.service';
import { RosterDetailsService } from 'src/app/services/roster-details.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmationDialogService } from 'src/app/shared/modules/confirmation-dialog/confirmation-dialog.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/app/config/env-config';
import { saveAs } from 'file-saver';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { fileUploadUrl } from '../../../config/env-config';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss'],
})

export class RecordsComponent implements OnInit {
  selected = 'driver';
  loadingnow = false;
  listItems = [];
  dashConfig = [];
  otherexpenses = [];
  records = [];
  breakdownDetail = [];
  expenseDetail = [];
  tripDetail = [];
  outstandingDetail = [];
  expenseDocumentList = [];
  vehicleCity = [];
  isDocument = false;
  selectedVehicle: any = null;
  selectCity: any = null;
  reason: any = null;
  vendorType: any = null;
  filterType: any = '';
  filterTypes: any = '';
  startDate: any = null;
  endDate: any = null;
  isCsv: any = false;
  activeItm: any;
  dropdownSettings: any;
  logbookDetail = [];
  groomingDetail = [];
  staffImage = [];
  vehicleExterior = [];
  vehicleInterior = [];
  allVendorList = [];
  assignVendor = false;
  tripDetails = false;
  selectedVandorInfo = '';
  selectedTripInfo = '';
  problem = '';
  vechileNo = '';
  dashItemList = [];
  dashItemsList = [];
  dashItemListCount;
  fixNeededCount;
  currCardfilter;
  currfilter;
  vendor = null;
  fileData = null;
  vendorCostEstimation = null;
  vendorTimeEstimation = null;
  expensepopup: boolean = false;
  seledtedrecord: any = '';
  expenseremark: any = '';
  userId: any = JSON.parse(localStorage.getItem('currentUser'))['data']['userID'];
  userType: any = JSON.parse(localStorage.getItem('currentUser'))['data']['roles'][0];
  outstandingpopup: boolean = false;
  outstandingremark: any = '';
  selectedoutstanding: any = '';
  outstandingusertype: any = '';
  hasmorerecords: boolean = false;
  skip: number = 0;
  limit: number = 50;
  loading: boolean = false;


  constructor(
    private rosterdetailsservice: RosterDetailsService,
    private CommonService: CommonService,
    private confirmationDialogService: ConfirmationDialogService,
    public cm: CardDetailsManagerService,
    public cs: CommonService,
    private http: HttpClient,
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,

  ) {
  }

  ngOnInit(): void {
    // console.log(this.userType, "usertype");
    this.getAllVehiclesList();
    this.getDashItemsList();
    this.getVendor();
    let stdate = moment(new Date()).subtract(1, 'days').toISOString()
    let enddate = moment(new Date()).toISOString()
    this.startDate = moment(stdate).format('YYYY-MM-DD');
    this.endDate = moment(enddate).format('YYYY-MM-DD');
    console.log(this.startDate, "start2");
    setInterval(() => {
      this.getDashItemsList();
    }, 60000);
    if (localStorage.getItem('masterdata')) {
      var masterdata = JSON.parse(localStorage.getItem('masterdata'));
      //console.log(masterdata);
      if (masterdata && masterdata.dashRecord.length > 0) {
        if (this.userType == 'ACCOUNTANT') {
          this.dashConfig = masterdata.dashRecord.filter((item: any) => item == 'DRIVEROUTSTANDING')
          this.filterType = this.dashConfig[0].toLowerCase();
        } else {
          this.dashConfig = masterdata.dashRecord;
          this.filterType = this.dashConfig[0].toLowerCase();
        }
        this.otherexpenses = masterdata.otherexpenses;
        this.vehicleCity = masterdata.vehicleCity;
        this.vendorType = 'helpnow'
        // console.log(this.dashConfig, this.filterType, 'first');
      }
    } else {
      this.getAllMasters();
    }

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'vehicleNumber',
      allowSearchFilter: true,
      enableCheckAll: false,
      maxHeight: 200,
      closeDropDownOnSelection: true,
    };
  }

  // Expense popup functionlity starts
  openExpensepopup(record: any) {
    this.expensepopup = true;
    this.seledtedrecord = record;

  }
  closeExpensepopup() {
    this.expensepopup = false;
  }

  ApproveClickExpense() {
    this.http.post(baseUrl + 'api/v1/expense/approvedExpense', {},
      {
        headers: this.cs.getHeaders(),
        params: {
          usertype: 'OPERATION_EXPENSE',
          approvalStatus: true as any,
          expenseId: this.seledtedrecord.expense.id,
          userId: this.userId,
          remark: this.expenseremark
        }
      }
    )
      .subscribe(
        (res) => {
          this.records = []
          this.expensepopup = false
          this.seledtedrecord = ''
          this.expenseremark = ''
          this.toastr.success('Expense Approved successfully')
          this.recordFilter();
        },
        (error) => {
          console.log(error);
          this.toastr.error('Something went wrong!!')

        }
      )
  }

  RejectClickExpense() {
    this.http.post(baseUrl + 'api/v1/expense/approvedExpense', {},
      {
        headers: this.cs.getHeaders(),
        params: {
          usertype: 'OPERATION_EXPENSE',
          approvalStatus: false as any,
          expenseId: this.seledtedrecord.expense.id,
          userId: this.userId,
          remark: this.expenseremark
        }
      }
    )
      .subscribe(
        (res) => {
          this.records = []
          this.expensepopup = false
          this.seledtedrecord = ''
          this.expenseremark = ''
          this.toastr.success('Expense Rejected successfully')
          this.recordFilter();

        },
        (error) => {
          console.log(error);
          this.toastr.error('Something went wrong!!')
        }
      )
  }

  // Expense popup functionality ends here 

  // Outstanding popup functionality starts here 

  openOutstandingpopup(outstanding: any) {
    this.outstandingpopup = true;
    this.selectedoutstanding = outstanding;
  }
  closeOutstandingpopup() {
    this.outstandingpopup = false
  }

  OutstandingApprove() {
    if (this.userType == 'ACCOUNTANT') {
      this.outstandingusertype = 'ACCOUNTANT'
    } else {
      this.outstandingusertype = 'OPERATION'
    }

    this.http.get(baseUrl + 'api/v1/outstanding/approveOutstanding',
      {
        headers: this.cs.getHeaders(),
        params: {
          usertype: this.outstandingusertype,
          approvalStatus: true as any,
          outStandingId: this.selectedoutstanding.outstanding.id,
          userId: this.userId,
          remark: this.outstandingremark
        }
      }
    )
      .subscribe(
        (res) => {
          // console.log(res);
          this.records = []
          this.outstandingpopup = false
          this.selectedoutstanding = ''
          this.outstandingremark = ''
          this.toastr.success('Outstanding Approved successfully')
          this.recordFilter();
        },
        (error) => {
          console.log(error);
          this.toastr.error('Something went wrong!!')

        }
      )
  }

  OutstandingReject() {

    if (this.userType == 'ACCOUNTANT') {
      this.outstandingusertype = 'ACCOUNTANT'
    } else {
      this.outstandingusertype = 'OPERATION'
    }

    this.http.get(baseUrl + 'api/v1/outstanding/approveOutstanding',
      {
        headers: this.cs.getHeaders(),
        params: {
          usertype: this.outstandingusertype,
          approvalStatus: false as any,
          outStandingId: this.selectedoutstanding.outstanding.id,
          userId: this.userId,
          remark: this.outstandingremark
        }
      }
    )
      .subscribe(
        (res) => {
          // console.log(res);
          this.records = []
          this.outstandingpopup = false
          this.selectedoutstanding = ''
          this.outstandingremark = ''
          this.toastr.success('Outstanding Rejected successfully')
          this.recordFilter();
        },
        (error) => {
          // console.log(error);
          this.toastr.error('Something went wrong!!')

        }
      )
  }

  // Outstanding popup functionality ends here 


  getlongStatus(input: any) {
    switch (input) {
      case 'PENDING_OPS':
        return 'Pending on Operations';
      case 'PENDING_ACC':
        return 'Pending on Accounts';
      case 'REJECT_OPS':
        return 'Rejected by Operations';
      case 'REJECT_ACC':
        return 'Rejected by Accounts';
      case 'PENDING_EXP':
        return 'Used in Expense';
      case 'REJECT_EXP':
        return 'Rejected by Operations';
      default:
        return input;
    }
  }

  reloadPage() {
    window.location.reload();
  }
  onItemSelect(item: any) {
    //console.log(item);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  // selectOpt(type)
  // {
  // console.log("type",type)

  // this.selected = type;
  // if(type == 'driver')
  // {
  // this.getDriverStaff();
  // }
  // else
  // if(type == 'vehicle')
  // {
  // this.getAllVehiclesList();
  // }
  // }
  getDashItemsList() {
    this.http
      .get(
        baseUrl +
        'api/v1/VehicleActionHistory/getVehicleHistoryByVehicleID/helpnow',
        { headers: this.cs.getHeaders() }
      )
      .subscribe(
        (res) => {
          this.dashItemsList = res['data'];
          this.cs.setCommValue('dashCount', this.dashItemsList.length);
        },
        (error) => {
          this.cs.autoLogout(error);
        }
      );
  }

  getAllVehiclesList() {
    this.loadingnow = true;
    this.rosterdetailsservice.getAllHelpNowVehicles().subscribe(
      (data) => {
        this.listItems = data['data'];
        // console.log("listItemsVehicle", this.listItems)
        this.loadingnow = false;
        this.listItems.forEach((item, index) => {
          if (item.vehicleModel) {
            item.vehicleNumber += ' ' + item.vehicleModel;
          }

          if (item.baseLocation) {
            item.vehicleNumber += ' ' + item.baseLocation;
          }
        });
      },
      (error) => {
        this.CommonService.autoLogout(error);
      }
    );
  }

  getVehicles() {
    this.selectedVehicle = null;
    if (this.selectCity && this.selectCity !== 'null') {
      this.getVehiclesListByCity();
    } else {
      this.getAllVehiclesList();
    }
  }

  getVehiclesListByCity() {
    this.loadingnow = true;
    this.rosterdetailsservice.getVehiclesListByCity(this.selectCity).subscribe(
      (data) => {
        this.listItems = data['data'];
        //console.log("listItemsVehiclecity", this.listItems)
        this.loadingnow = false;
        this.listItems.forEach((item, index) => {
          if (item.vehicleModel) {
            item.vehicleNumber += ' ' + item.vehicleModel;
          }

          if (item.baseLocation) {
            item.vehicleNumber += ' ' + item.baseLocation;
          }
        });
      },
      (error) => {
        this.CommonService.autoLogout(error);
      }
    );
  }

  getAllMasters() {
    this.loadingnow = true;
    this.CommonService.getAllMasters().subscribe(
      (data) => {
        var masterdata = data['data'];
        localStorage.setItem('masterdata', JSON.stringify(masterdata));
        if (this.userType == 'ACCOUNTANT') {
          this.dashConfig = masterdata.dashRecord.filter((item: any) => item == 'DRIVEROUTSTANDING')
          this.filterType = this.dashConfig[0].toLowerCase();
        } else {
          this.dashConfig = masterdata.dashRecord;
          this.filterType = this.dashConfig[0].toLowerCase();
        }
        this.otherexpenses = masterdata.otherexpenses;
        this.loadingnow = false;
      },
      (error) => {
        this.CommonService.autoLogout(error);
      }
    );
  }
  downloadCsv() {
    this.isCsv = true;
    // this.recordFilter();
    var filter = '';
    var str = '';
    if (this.selectedVehicle && this.selectedVehicle.length > 0) {
      let vehicleNumber = this.selectedVehicle[0].vehicleNumber.split(' ')[0];
      filter += str + 'vehicleNumber=' + vehicleNumber;
      str = '&';
    }
    if (this.selectedVehicle && this.selectedVehicle.length > 0) {
      filter += str + 'vehicleId=' + this.selectedVehicle[0].id;
      str = '&';
    }
    if (this.filterType == 'other' && this.reason) {
      filter += str + 'filterType=' + this.filterType + '&' + 'reason=' + this.reason;
      str = '&';
    } else if (this.filterType && this.filterType != 'vehiclerepair') {
      filter += str + 'filterType=' + this.filterType;
      str = '&';
    } else if (this.filterType == 'vehiclerepair') {
      filter += 'filterType=other&reason=vehiclerepair';
      str = '&';
    }

    if (this.filterType == 'trip') {
      filter += str + 'vendor=' + this.vendorType
    }

    if (this.startDate) {
      var startDate = moment(new Date(this.startDate)).toISOString();
      filter += str + 'startDate=' + startDate;
      str = '&';
    }
    if (this.endDate) {
      var endDate = moment
        .utc(new Date(this.endDate))
        .endOf('day')
        .toISOString();
      filter += str + 'endDate=' + endDate;
      str = '&';
    }
    if (this.selectCity) {
      filter += str + 'city=' + this.selectCity;
      str = '&';
    }
    // let skiplimit = '&skip=' + this.skip + '&limit=' + this.limit
    let skiplimit = ''
    this.getRecordsByFilter(filter, skiplimit)
  }

  recordFilter() {
    this.skip = 0;
    var filter = '';
    var str = '';
    if (this.selectedVehicle && this.selectedVehicle.length > 0) {
      let vehicleNumber = this.selectedVehicle[0].vehicleNumber.split(' ')[0];
      filter += str + 'vehicleNumber=' + vehicleNumber;
      str = '&';
    }
    if (this.selectedVehicle && this.selectedVehicle.length > 0) {
      filter += str + 'vehicleId=' + this.selectedVehicle[0].id;
      str = '&';
    }
    // else{
    // filter += str+"vehicleId="+null;
    // str = "&";
    // }
    // if (this.filterType) {
    //   filter += str + 'filterType=' + this.filterType;
    //   str = '&';
    // }
    // if (this.filterType) {
    //   filter += str + 'filterType=' + 'other';
    //   str = '&';
    // }
    // if(this.filterType && this.reason)
    // if(this.filterType == 'vehiclerepair'){
    //   this.filterType = 'other'
    // }
    if (this.filterType == 'other' && this.reason) {
      filter += str + 'filterType=' + this.filterType + '&' + 'reason=' + this.reason;
      str = '&';
    } else if (this.filterType && this.filterType != 'vehiclerepair') {
      filter += str + 'filterType=' + this.filterType;
      str = '&';
    } else if (this.filterType == 'vehiclerepair') {
      filter += 'filterType=other&reason=vehiclerepair';
      str = '&';
    }

    if (this.filterType == 'trip') {
      filter += str + 'vendor=' + this.vendorType
    }

    if (this.startDate) {
      // console.log(startDate, "startf");
      var startDate = moment(new Date(this.startDate)).toISOString();
      filter += str + 'startDate=' + startDate;
      str = '&';
    }
    if (this.endDate) {
      //var endDate = moment(new Date(this.endDate)).endOf('day').toISOString();
      var endDate = moment
        .utc(new Date(this.endDate))
        .endOf('day')
        .toISOString();
      filter += str + 'endDate=' + endDate;
      str = '&';
    }
    if (this.selectCity) {
      // console.log('test');
      // console.log(this.selectCity);
      filter += str + 'city=' + this.selectCity;
      str = '&';
    }
    // console.log(filter, "filter");
    let skiplimit = '&skip=' + this.skip + '&limit=' + this.limit
    this.getRecordsByFilter(filter, skiplimit);

  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    // Check if the user has scrolled to the bottom of the page
    if (this.hasmorerecords && !this.loading) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.loadmoreRecords();
      }
    }
  }

  onFilterchange() {
    this.records = []
    this.hasmorerecords = false
    this.loading = false
  }


  loadmoreRecords() {
    this.skip = this.skip + 50
    var filter = '';
    var str = '';
    if (this.selectedVehicle && this.selectedVehicle.length > 0) {
      let vehicleNumber = this.selectedVehicle[0].vehicleNumber.split(' ')[0];
      filter += str + 'vehicleNumber=' + vehicleNumber;
      str = '&';
    }
    if (this.selectedVehicle && this.selectedVehicle.length > 0) {
      filter += str + 'vehicleId=' + this.selectedVehicle[0].id;
      str = '&';
    }
    if (this.filterType == 'other' && this.reason) {
      filter += str + 'filterType=' + this.filterType + '&' + 'reason=' + this.reason;
      str = '&';
    } else if (this.filterType && this.filterType != 'vehiclerepair') {
      filter += str + 'filterType=' + this.filterType;
      str = '&';
    } else if (this.filterType == 'vehiclerepair') {
      filter += 'filterType=other&reason=vehiclerepair';
      str = '&';
    }

    if (this.filterType == 'trip') {
      filter += str + 'vendor=' + this.vendorType
    }

    if (this.startDate) {
      var startDate = moment(new Date(this.startDate)).toISOString();
      filter += str + 'startDate=' + startDate;
      str = '&';
    }
    if (this.endDate) {
      var endDate = moment
        .utc(new Date(this.endDate))
        .endOf('day')
        .toISOString();
      filter += str + 'endDate=' + endDate;
      str = '&';
    }
    if (this.selectCity) {
      filter += str + 'city=' + this.selectCity;
      str = '&';
    }
    let skiplimit = '&skip=' + this.skip + '&limit=' + this.limit
    this.getRecordsByFilter(filter, skiplimit)
  }

  getRecordsByFilter(filter, skiplimit) {
    this.loading = true
    this.http
      .get(baseUrl + 'api/v2/records/getRecordsByFilter?' + filter + skiplimit, {
        headers: this.CommonService.getHeaders(),
      })
      .subscribe(
        (res) => {
          this.loading = false
          if (
            this.filterType == 'o2refill' ||
            this.filterType == 'fuelrefill' ||
            this.filterType == 'other' ||
            this.filterType == 'vehiclerepair'
          ) {
            if (this.isCsv) {
              this.records = res['data']
            }
            else if (this.records['expenseDetail'] && this.records['expenseDetail'].length > 0) {
              if (res['data']['expenseDetail']) {

                this.records = { ...res['data'], expenseDetail: [...this.records['expenseDetail'], ...res['data']['expenseDetail']], recordsCount: this.records['recordsCount'] + res['data']['recordsCount'] }
              }
            } else {

              this.records = res['data']
            }
          }
          else if (this.filterType == 'driveroutstanding') {
            if (this.isCsv) {
              this.records = res['data']
            }
            else if (this.records['outstandingDetail'] && this.records['outstandingDetail'].length > 0) {
              if (res['data']['outstandingDetail']) {

                this.records = { ...res['data'], outstandingDetail: [...this.records['outstandingDetail'], ...res['data']['outstandingDetail']], recordsCount: this.records['recordsCount'] + res['data']['recordsCount'] }
              }
            } else {

              this.records = res['data']
            }
          }
          else if (this.filterType == 'breakdown') {

            if (this.isCsv) {
              this.records = res['data']
            }
            else if (this.records['breakdownDetail'] && this.records['breakdownDetail'].length > 0) {
              if (res['data']['breakdownDetail']) {

                this.records = { ...res['data'], breakdownDetail: [...this.records['breakdownDetail'], ...res['data']['breakdownDetail']], recordsCount: this.records['recordsCount'] + res['data']['recordsCount'] }
              }
            } else {

              this.records = res['data']
            }
          }
          else if (this.filterType == 'trip') {

            if (this.isCsv) {
              this.records = res['data']
            }
            else if (this.records['tripDetail'] && this.records['tripDetail'].length > 0) {
              if (res['data']['tripDetail']) {

                this.records = { ...res['data'], tripDetail: [...this.records['tripDetail'], ...res['data']['tripDetail']], recordsCount: this.records['recordsCount'] + res['data']['recordsCount'] }
              }
            } else {

              this.records = res['data']
            }
          }
          else if (this.filterType == 'grooming') {

            if (this.isCsv) {
              this.records = res['data']
            }
            else if (this.records['groomingDetail'] && this.records['groomingDetail'].length > 0) {
              if (res['data']['groomingDetail']) {

                this.records = { ...res['data'], groomingDetail: [...this.records['groomingDetail'], ...res['data']['groomingDetail']], recordsCount: this.records['recordsCount'] + res['data']['recordsCount'] }
              }
            } else {

              this.records = res['data']
            }
          }
          else if (this.filterType == 'logbook') {

            if (this.isCsv) {
              this.records = res['data']
            }
            else if (this.records['logbookDetail'] && this.records['logbookDetail'].length > 0) {
              if (res['data']['logbookDetail']) {

                this.records = { ...res['data'], logbookDetail: [...this.records['logbookDetail'], ...res['data']['logbookDetail']], recordsCount: this.records['recordsCount'] + res['data']['recordsCount'] }
              }
            } else {
              this.records = res['data']
            }
          }

          // this.records = res['data'];

          // console.log(res['data'].recordsCount, "jg");

          if (res['data'].recordsCount == 0) {
            this.hasmorerecords = false
          } else {
            this.hasmorerecords = true
          }

          if (this.records) {
            var data = [];


            if (
              this.filterType == 'o2refill' ||
              this.filterType == 'fuelrefill' ||
              this.filterType == 'other' ||
              this.filterType == 'vehiclerepair'
            ) {
              if (
                this.isCsv &&
                this.records['expenseDetail'] &&
                this.records['expenseDetail'].length > 0
              ) {
                var info = {};
                this.records['expenseDetail'].forEach((record, index) => {
                  if (this.filterType == 'fuelrefill') {
                    info = {
                      'Transaction ID': record.expense.transactionId,
                      'Transaction Date': this.changeDateFormat(
                        record.expense.createdAt
                      ),
                      'Transaction Time': this.changeTimeFormat(
                        record.expense.createdAt
                      ),
                      City: record.expense.city,
                      'Vehicle Number': '',
                      'Driver Name': record.driver.name,
                      'Vendor Name': '',
                      'Litres Refiled': record.expense.fuelRefill,
                      'Odometer Reading': record.expense.odoMeterReading,
                      'Odometer Image': '',
                      'Transaction Amount': record.expense.cost,
                      Comments: record.expense.comment,
                      'Status': record.expense.status
                    };

                    if (record.driver.vehicle) {
                      info['Vehicle Number'] = record.vehicleNumber;
                    }
                    if (record.expense.vendor) {
                      info['Vendor Name'] = record.expense.vendor.name;
                    }

                    if (record.expense.expenseDocumentList.length > 0) {
                      info['Odometer Image'] =
                        record.expense.expenseDocumentList.toString();
                    }
                    data.push(info);
                  } else if (this.filterType == 'o2refill') {
                    info = {
                      'Transaction ID': record.expense.transactionId,
                      'Transaction Date': this.changeDateFormat(
                        record.expense.createdAt
                      ),
                      'Transaction Time': this.changeTimeFormat(
                        record.expense.createdAt
                      ),
                      City: record.expense.city,
                      'Vehicle Number': '',
                      'Driver Name': record.driver.name,
                      'Vendor Name': '',
                      'Clinder Type': record.expense.cylinderType,
                      'O2 Condition': '',
                      'Transaction Amount': record.expense.cost,
                      'Bill Image': '',
                      Comments: record.expense.comment,
                      'Status': record.expense.status
                    };

                    if (record.vehicleNumber) {
                      info['Vehicle Number'] = record.vehicleNumber;
                    }
                    if (record.expense.vendor) {
                      info['Vendor Name'] = record.expense.vendor.name;
                    }

                    if (record.expense.expenseDocumentList.length > 0) {
                      info['Bill Image'] =
                        record.expense.expenseDocumentList.toString();
                    }
                    data.push(info);
                    // console.log(info, "sd");

                  } else if (this.filterType == 'vehiclerepair') {
                    info = {
                      'Transaction ID': record.expense.transactionId,
                      'Transaction Date': this.changeDateFormat(
                        record.expense.createdAt
                      ),
                      'Transaction Time': this.changeTimeFormat(
                        record.expense.createdAt
                      ),
                      City: record.expense.city,
                      'Vehicle Number': '',
                      'Driver Name': record.driver.name,
                      'Vendor Name': '',
                      Reason: record.expense.type,
                      'Invoice Number': record.expense.invoiceNumber,
                      'Transaction Amount': record.expense.cost,
                      'Bill Image': '',
                      'Odometer Image': '',
                      Comments: record.expense.comment,
                      'Status': record.expense.status
                    };

                    if (record.vehicleNumber) {
                      info['Vehicle Number'] = record.vehicleNumber;
                    }
                    if (record.expense.vendor) {
                      info['Vendor Name'] = record.expense.vendor.name;
                    }

                    if (record.expense.expenseDocumentList.length > 0) {
                      info['Bill Image'] =
                        record.expense.expenseDocumentList.toString();
                    }
                    if (record.expense.odometerImageList.length > 0) {
                      info['Odometer Image'] =
                        record.expense.odometerImageList.toString();
                    }
                    data.push(info);
                    console.log(data, "vehicle_other")
                  }
                  else if (this.filterType == 'other') {
                    info = {
                      'Transaction ID': record.expense.transactionId,
                      'Transaction Date': this.changeDateFormat(
                        record.expense.createdAt
                      ),
                      'Transaction Time': this.changeTimeFormat(
                        record.expense.createdAt
                      ),
                      City: record.expense.city,
                      'Vehicle Number': '',
                      'Driver Name': record.driver.name,
                      'Vendor Name': '',
                      Reason: record.expense.type,
                      'Invoice Number': record.expense.invoiceNumber,
                      'Transaction Amount': record.expense.cost,
                      'Bill Image': '',
                      'Odometer Image': '',
                      Comments: record.expense.comment,
                      'Status': record.expense.status
                    };

                    if (record.vehicleNumber) {
                      info['Vehicle Number'] = record.vehicleNumber;
                    }
                    if (record.expense.vendor) {
                      info['Vendor Name'] = record.expense.vendor.name;
                    }

                    if (record.expense.expenseDocumentList.length > 0) {
                      info['Bill Image'] =
                        record.expense.expenseDocumentList.toString();
                    }
                    if (record.expense.odometerImageList.length > 0) {
                      info['Odometer Image'] =
                        record.expense.odometerImageList.toString();
                    }
                    data.push(info);

                  }
                });

                this.downloadFile(data);
              } else {
                this.expenseDetail = this.records['expenseDetail'];
              }
            }

            else if (this.filterType == 'breakdown') {
              if (
                this.isCsv &&
                this.records['breakdownDetail'] &&
                this.records['breakdownDetail'].length > 0
              ) {
                this.records['breakdownDetail'].forEach((record, index) => {
                  var breakInfo = {
                    'Transaction ID': record.breakdown.transactionId,
                    'Transaction Date': this.changeDateFormat(
                      record.breakdown.createdAt
                    ),
                    'Transaction Time': this.changeTimeFormat(
                      record.breakdown.createdAt
                    ),
                    City: record.breakdown.city,
                    'Vehicle Number': '',
                    'Driver Name': record.driver.name,
                    'Choose Problem': record.breakdown.problem,
                    'Vendor Name': '',
                    'Invoice Number': record.breakdown.invoiceNumber,
                    'Transaction Amount': record.breakdown.breakDownCost,
                    'Bill Image': '',
                    Comments: record.breakdown.comment,
                  };

                  if (record.breakdown.vehicleNumber) {
                    breakInfo['Vehicle Number'] =
                      record.breakdown.vehicleNumber;
                  }
                  if (record.breakdown.vendorDetails) {
                    breakInfo['Vendor Name'] =
                      record.breakdown.vendorDetails.name;
                  }

                  if (record.breakdown.gstBillDocumentList != null) {
                    if (record.breakdown.gstBillDocumentList.length > 0) {
                      breakInfo['Bill Image'] =
                        record.breakdown.gstBillDocumentList.toString();
                    }
                  }
                  data.push(breakInfo);
                  console.log(breakInfo, "breakInfo");

                });

                this.downloadFile(data);
              } else {
                this.breakdownDetail = this.records['breakdownDetail'];
              }
            } else if (this.filterType == 'trip') {
              // console.log('tripinfo', this.tripDetail, this.records['tripDetail'], this.records['tripDetail']?.length);
              if (
                this.isCsv &&
                this.records['tripDetail'] &&
                this.records['tripDetail']?.length > 0
              ) {

                this.records['tripDetail'].forEach((record, index) => {
                  var tripInfo = {
                    Date: this.changeDateFormat(record.createdAt),
                    City: '',
                    Channel: record.channel,
                    'CC Agent Name': record.assignedByName,
                    'Vehicle Number': '',
                    'Driver Name': record?.driverDetails?.name,
                    'Helper Name': '',
                    'Trip Alloted Time': this.changeTimeFormat(
                      record.createdAt
                    ),
                    'Vehicle Reached Time': this.changeTimeFormat(
                      record.vehicleReachedTime
                    ),
                    'Trip Start Time': this.changeTimeFormat(
                      record.tripStartTime
                    ),
                    'Pick-up Point': record.tripFromLocation,
                    'Drop-off Point': record.tripToLocation,
                    'Trip End Time': this.changeTimeFormat(record.tripEndTime),
                    'Trevel Time': record.estimatedTripTime,
                    'Trip KM': record.distanceFromSourceToDestination,
                    'Total KM': record.totalDistance,
                    'Customer Name': '',
                    'Customer Number': '',
                    'Ambulance Type': record.vehicleDetails.ambulanceType,
                    'Case Type': record.tripType,
                    'Extra Helper Required': '',
                    'Wating Charges': record.totalWaitTimeCost,
                    'Trip Amount': record.amount,
                    'Payment Mode': '',
                    Remarks: record.tripComments,
                  };

                  if (record?.paymentList?.length > 0) {
                    tripInfo['Payment Mode'] =
                      record.paymentList[0].paymentType;
                  }

                  if (record.vehicleDetails) {
                    tripInfo['Vehicle Number'] =
                      record.vehicleDetails.vehicleNumber;
                  }
                  if (record.patientDetails) {
                    tripInfo['Customer Name'] =
                      record.patientDetails.patientName;
                    tripInfo['Customer Number'] =
                      record.patientDetails.patientMobile;
                  }
                  if (record?.driverDetails?.helper) {
                    tripInfo['Helper Name'] = record?.driverDetails?.helper?.name;
                  }

                  data.push(tripInfo);
                });

                this.downloadFile(data);
              } else {
                this.tripDetail = this.records['tripDetail'];
                // console.log(this.tripDetail, "tripinfo123");
              }
            } else if (this.filterType == 'driveroutstanding') {
              if (
                this.isCsv &&
                this.records['outstandingDetail'] &&
                this.records['outstandingDetail'].length > 0
              ) {
                this.records['outstandingDetail'].forEach(
                  (outstanding, index) => {
                    var outInfo = {
                      'Transaction ID': outstanding.outstanding.transactionId,
                      'Transaction Date': this.changeDateFormat(
                        outstanding.outstanding.createdAt
                      ),
                      'Vehicle Number': outstanding?.vehicleNumber,
                      'Driver Name': outstanding.driver.name,
                      'Payment Mode': outstanding.outstanding.modeOfPayment,
                      'Payment Status': this.getlongStatus(outstanding.outstanding.paymentStatus),
                      'Outstanding Type':
                        outstanding.outstanding.outstandingType,
                      'Transaction Amount':
                        outstanding.outstanding.outStandingAmount,
                      'Bill Image': outstanding.outstanding?.outstandingAmountDocumentList?.toString(),
                    };

                    // if (outstanding.vehicleNumber) {
                    //   outInfo['Vehicle Number'] = outstanding.vehicleNumber;
                    // }

                    // if (
                    //   outstanding.outstanding.outstandingAmountDocumentList
                    //     .length > 0
                    // ) {
                    //   outInfo['Bill Image'] =
                    //     outstanding.outstanding.outstandingAmountDocumentList.toString();
                    // }
                    data.push(outInfo);
                  }
                );

                this.downloadFile(data);
              } else {
                this.outstandingDetail = this.records['outstandingDetail'];
              }
            } else if (this.filterType == 'grooming') {
              if (
                this.isCsv &&
                this.records['groomingDetail'] &&
                this.records['groomingDetail'].length > 0
              ) {
                this.records['groomingDetail'].forEach((grooming, index) => {
                  var outInfo = {
                    'Transaction ID': grooming.grooming.id,
                    'Transaction Date': this.changeDateFormat(
                      grooming.grooming.createdAt
                    ),
                    'Vehicle Number': '',
                    'Driver Name': grooming.driver.name,
                    'Vehicle Staff Image': '',
                    'Vehicle Exterior Image': '',
                    'Vehicle Interior Image': '',
                  };

                  if (grooming.grooming.vehicleNumber) {
                    outInfo['Vehicle Number'] = grooming.grooming.vehicleNumber;
                  }

                  if (grooming.grooming.vehicleGroomingDocumentList != null) {
                    if (
                      grooming.grooming.vehicleGroomingDocumentList
                        .vehicleInterior.length > 0
                    ) {
                      outInfo['Vehicle Interior Image'] =
                        grooming.grooming.vehicleGroomingDocumentList.vehicleInterior.toString();
                    }
                    if (
                      grooming.grooming.vehicleGroomingDocumentList
                        .vehicleExterior.length > 0
                    ) {
                      outInfo['Vehicle Exterior Image'] =
                        grooming.grooming.vehicleGroomingDocumentList.vehicleExterior.toString();
                    }
                  }
                  if (grooming.grooming.driverGroomingDocumentList != null) {
                    if (
                      grooming.grooming.driverGroomingDocumentList.staffImage
                        .length > 0
                    ) {
                      outInfo['Vehicle Staff Image'] =
                        grooming.grooming.driverGroomingDocumentList.staffImage.toString();
                    }
                  }
                  data.push(outInfo);
                });

                this.downloadFile(data);
              } else {
                this.groomingDetail = this.records['groomingDetail'];
              }
            } else if (this.filterType == 'logbook') {
              if (
                this.isCsv &&
                this.records['logbookDetail'] &&
                this.records['logbookDetail'].length > 0
              ) {
                this.records['logbookDetail'].forEach((logbook, index) => {
                  var outInfo = {
                    'Trip Id': logbook.logbook.tripUniqueId,
                    'Transaction Date': this.changeDateFormat(
                      logbook.logbook.createdAt
                    ),
                    'Vehicle Number': logbook.logbook.vehicleNumber,
                    'Driver Name': logbook.logbook.driverName,
                    'helper Name': logbook.logbook.helperName,
                    'Payment Mode': logbook.logbook.paymentMode,
                    'Payment Collected': logbook.logbook.paymentCollected,
                    'Logbook Status': logbook.logbook.logbookStatus,
                    'Case Type': logbook.logbook.caseType,
                    'Pickup Location': logbook.logbook.pickUpLocation,
                    'Dropoff Location': logbook.logbook.dropLocation,
                    'CC Agent Name': logbook.logbook.ccAgentName,
                    'Patient Name': logbook.logbook.patientName,
                    'Relative Mobile No.': logbook.logbook.relativeMobileNumber,
                    'Odometer Start Reading':
                      logbook.logbook.odometerStartReading,
                    'Odometer End Reading': logbook.logbook.odometerEndReading,
                    'Total KMs': logbook.logbook.totalTraveledKM,
                    'Waiting time cost': logbook.logbook.waitingTimeCost,
                  };
                  data.push(outInfo);
                });

                this.downloadFile(data);
              }
              else {
                this.logbookDetail = this.records['logbookDetail'];
              }
            }
            this.isCsv = false;
          }
        },
        (error) => {
          this.CommonService.autoLogout(error);
        }
      );
  }

  viewDocument(a) {
    if (this.filterType == 'o2refill' || this.filterType == 'other') {
      console.log(a.expenseDocumentList);
      this.expenseDocumentList = [...new Set(a.expenseDocumentList)];
    } else if (this.filterType == 'fuelrefill') {
      console.log(a);
      this.expenseDocumentList = [...new Set(a)];
    } else if (this.filterType == 'breakdown') {
      this.expenseDocumentList = [...new Set(a.gstBillDocumentList)];
    } else if (this.filterType == 'driveroutstanding') {
      this.expenseDocumentList = [...new Set(a.outstandingAmountDocumentList)];
    } else if (this.filterType == 'grooming') {
      this.expenseDocumentList = [...new Set(a)];
    } else if (this.filterType == 'logbook') {
      this.expenseDocumentList = [...new Set(a)];
    } else if (this.filterType == 'vehiclerepair') {
      this.expenseDocumentList = [...new Set(a.gstBillDocumentList)];
    }

    //console.log(this.expenseDocumentList);
    this.isDocument = true;
  }

  hideImagePopup() {
    this.isDocument = false;
  }

  getDriverStaff() {
    this.loadingnow = true;
    this.rosterdetailsservice.getAllDriverlist().subscribe(
      (data) => {
        this.listItems = data['data'];
        //console.log("listItemsDriver", this.listItems)
        this.loadingnow = false;
      },
      (error) => {
        this.CommonService.autoLogout(error);
      }
    );
  }
  getVendor() {
    return this.http
      .get(baseUrl + 'api/v1/vendor/getByVendorType/breakdownvendor', {
        headers: this.cs.getHeaders(),
      })
      .subscribe(
        (res) => {
          this.allVendorList = res['data'];
          // console.log(this.allVendorList,"riyaz");
        },
        (error) => {
          this.cs.autoLogout(error);
        }
      );
  }

  edit(type, item) {
    //console.log("edit",type,item)
    this.cm.setActive('editRecords', item);
    this.activeItm = item;
    //console.log("activeItm",this.activeItm)
  }

  delete(type, item) {
    this.activeItm = item;
    //console.log("delete",type,item)
    this.confirmationDialogService
      .confirm('Please confirm', 'Do you really want to delete the record ?')
      .then((confirmed) => {
        console.log('User confirmed:', confirmed);
      })
      .catch(() => console.log('User dismissed'));
  }

  downloadFile(data: any) {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(
      blob,
      this.filterType + '_' + this.changeDateFormat(new Date()) + '.csv'
    );
  }

  changeDateFormat(date: any) {
    if (date) {
      return moment(new Date(date)).format('DD/MM/yyyy');
    }
  }
  changeTimeFormat(date: any) {
    if (date) {
      return moment(new Date(date)).format('h:mm a');
    }
  }
  showPopup(info) {
    console.log('inform>', info);
    console.log(info.breakdown.vehicleNumber, "riyaz");
    this.vechileNo = info.breakdown.vehicleNumber;
    this.selectedVandorInfo = info;
    this.problem = info.breakdown.problem;
    this.assignVendor = true;

  }
  assignVendorFun() {
    var error = false;
    var formInfo = { breakDownStatus: 'vendorassigned' };

    if (this.selectedVandorInfo) {
      formInfo['transactionId'] =
        this.selectedVandorInfo['breakdown']['transactionId'];
    }
    if (
      this.selectedVandorInfo['breakdown']['breakDownStatus'].toLowerCase() ==
      'pending'
    ) {
      var vendorInfo = this.getVendorInfoById(this.vendor);
      if (
        vendorInfo &&
        this.vendorCostEstimation &&
        this.vendorTimeEstimation
      ) {
        vendorInfo['vendorCostEstimation'] = this.vendorCostEstimation;
        vendorInfo['vendorTimeEstimation'] = this.vendorTimeEstimation;
        formInfo['vendorDetails'] = vendorInfo;
      } else {
        error = true;
      }
    } else {
      formInfo['resolved'] = true;
      formInfo['breakDownStatus'] = 'completed';
    }

    if (this.fileData) {
      const formData = new FormData();
      formData.append('file', this.fileData);
      formData.append('serviceName', 'DriverApp');
      formData.append(
        'userID',
        this.selectedVandorInfo['breakdown']['driverId']
      );
      // this.http.post("https://file-service.prod.gethelpnow.in/api/v1/file/upload",formData,{ headers: this.cs.getHeaders() })
      //file upload url
      this.http
        .post(fileUploadUrl, formData, { headers: this.cs.getHeaders() })
        .subscribe(
          (res) => {
            var response = res['data'].url;
            var gstBillDocumentList = [response];
            formInfo['gstBillDocumentList'] = gstBillDocumentList;
            this.postData(formInfo, error);
            this.fileData = '';
            // this.ngOnInit();

          },
          (error) => {
            this.cs.autoLogout(error);
          }
        );
    } else {
      if (
        this.selectedVandorInfo['breakdown']['gstBillDocumentList'].length ==
        0 &&
        this.selectedVandorInfo['breakdown']['breakDownStatus'].toLowerCase() !=
        'pending'
      ) {
        error = true;
      }
      this.postData(formInfo, error);
    }
  }

  postData(formInfo: any, error: any) {
    if (error) {
      alert('Please fill required data.');
      return false;
    }
    this.http
      .post(baseUrl + 'api/v1/breakdown/updateByTransactionId', formInfo, {
        headers: this.cs.getHeaders(),
      })
      .subscribe(
        (res) => {
          var response = res['data'];
          this.assignVendor = false;
          this.getDashItemsList();
          this.vendorCostEstimation = '';
          this.vendorTimeEstimation = '';
          this.recordFilter();
          // Router.reload()
        },
        (error) => {
          this.cs.autoLogout(error);
        }
      );
  }
  hidePopup() {
    this.assignVendor = false;
    this.tripDetails = false;
  }
  getVendorInfoById(id) {
    var vendorInfo = '';
    this.allVendorList.forEach((vendor, index) => {
      if (vendor.id == id) {
        vendorInfo = vendor;
      }
    });
    return vendorInfo;
  }
  onFileSelect(event) {
    this.fileData = null;
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0];
    }
  }
  showimgs(unique) {
    //let unique = [...new Set(a.expenseDocumentList)];
    //console.log(unique)

    unique.forEach((u) => {
      window.open(u.toString());
    });
  }
}
