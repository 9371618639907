import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'round-alert',
  templateUrl: './round-alert.component.html',
  styleUrls: ['./round-alert.component.scss']
})
export class RoundAlertComponent implements OnInit {
  @Input() type: string;
  constructor() { }

  ngOnInit(): void {
  }

}
