import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CardDetailsManagerService } from 'src/app/services/card-details-manager.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'blue-card-details',
  templateUrl: './blue-card-details.component.html',
  styleUrls: ['./blue-card-details.component.scss']
})
export class BlueCardDetailsComponent implements OnInit {
  @Input() activated: boolean = false;
  constructor(public cdman:CardDetailsManagerService,public cs:CommonService) { }
  alldata;
  ngOnInit(): void {
  }

  closeCard()
  {
    this.cdman.dismiss('bcDetails');
  }


  ngOnChanges(changes: SimpleChanges) {
    if(this.activated)
    {
      console.log("it changeddd",this.activated,this.cdman.getModalData())
      this.alldata = this.cdman.getModalData();
    }
}

}
