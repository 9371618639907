import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RosterDetailsService } from '../../../services/roster-details.service';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { RoasterObj, RoasterObjAdapter } from '../../../models/roaster.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { baseUrl } from '../../../config/env-config';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-roaster',
  templateUrl: './roaster.component.html',
  styleUrls: ['./roaster.component.scss'],
})
export class RoasterComponent implements OnInit {
  loadingnow = false;
  istrip = false;
  driverPopup = false;
  listItems = [];
  tripDetail = [];
  selectedVehicle = null;
  selectedDriverVehicle = null;
  toggle_shift = false;
  shift1Driver = null;
  shift1FromDate = null;
  shift1ToDate = null;
  shift1Recurring = null;

  shift2Driver = null;
  shift2FromDate = null;
  shift2ToDate = null;
  shift2Recurring = null;

  vehicleId = null;
  driverVehicleId = null;
  calDateObj: any = [];
  events: any = [];
  records: any = [];
  driverItems: any = [];
  todayDate: any = new Date();
  dropdownSettings: any;

  constructor(
    private rosterdetailsservice: RosterDetailsService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private CommonService: CommonService,
    private adapter: RoasterObjAdapter
  ) {}

  ngOnInit(): void {
    let currentDate = new Date();
    var dateObj = this.createCalendar(currentDate);
    var allDates = this.getDates(dateObj.start, dateObj.end);
    this.calDateObj.dates = allDates;
    //console.log(this.calDateObj.dates);
    this.getAllVehiclesList();
    this.getDriverStaff();

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'vehicleNumber',
      allowSearchFilter: true,
      enableCheckAll: false,
      closeDropDownOnSelection: true,
      maxHeight: 200,
    };
  }
  onItemSelect(item: any) {
    //console.log(item);
    this.vehicleId = item.id;
    this.getRoasterFilter();
  }
  onItemDeSelect(item: any) {
    this.selectedVehicle = null;
    this.vehicleId = null;
  }

  onDriverSelect(item: any) {
    //console.log(item);
    this.driverVehicleId = item.id;
  }
  onDriverDeSelect(item: any) {
    this.selectedDriverVehicle = null;
    this.driverVehicleId = null;
  }
  onSelectAll(items: any) {
    //console.log(items);
  }

  getDates(startDate: any, stopDate: any) {
    var dateArray = new Array();
    var currentDate = startDate;

    while (currentDate <= stopDate) {
      var isMonth = 'current';
      var eventInfo = [];
      var todayMonth = moment(this.todayDate).format('M');
      var today = moment(currentDate).format('M');
      if (todayMonth > today) {
        isMonth = 'past';
      } else if (
        moment(this.todayDate).format('M') < moment(currentDate).format('M')
      ) {
        isMonth = 'future';
      }
      var eventInfo = this.getEventByDate(currentDate);
      dateArray.push({
        date: new Date(currentDate),
        isMonth: isMonth,
        eventInfo: eventInfo,
      });
      currentDate = moment(currentDate, 'YYYY-MM-DD');
      currentDate.add(1, 'days');
      currentDate = moment(currentDate).format('YYYY-MM-DD');
    }
    return dateArray;
  }
  getAllVehiclesList() {
    this.loadingnow = true;
    this.rosterdetailsservice.getAllHelpNowVehicleStatus().subscribe((data) => {
      this.listItems = data['data'];
      console.log("listItemsVehicle", this.listItems)
      this.loadingnow = false;
      this.listItems.forEach((item, index) => {
        if (item.vehicleModel) {
          item.vehicleNumber += ' ' + item.vehicleModel;
        }

        if (item.baseLocation) {
          item.vehicleNumber += ' ' + item.baseLocation;
        }
      });
    });
  }

  createCalendar(currentDate: any) {
    this.todayDate = currentDate;
    this.calDateObj.currentDate = currentDate;
    const startOfMonth = moment(currentDate)
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD hh:mm');
    const endOfMonth = moment(currentDate)
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD hh:mm');
    const date = moment(startOfMonth);
    const dow = date.day();
    var new_date = moment(startOfMonth, 'YYYY-MM-DD');
    if (dow > 1) {
      new_date.subtract(dow - 1, 'days');
    }
    var enddate = moment(endOfMonth);
    var endDay = enddate.day();
    var new_end_date = moment(endOfMonth, 'YYYY-MM-DD');
    if (endDay > 0) {
      new_end_date.add(7 - endDay, 'days');
    }

    var obj = {
      start: moment(new_date).format('YYYY-MM-DD hh:mm'),
      end: moment(new_end_date).format('YYYY-MM-DD hh:mm'),
    };
    return obj;
  }

  preMonth() {
    var new_date = moment(this.todayDate, 'YYYY-MM-DD');
    new_date.subtract(1, 'month');
    this.todayDate = moment(new_date).format('YYYY-MM-DD hh:mm');
    this.getRoasterFilter();
    //var dateObj = this.createCalendar(this.todayDate);
    //var allDates = this.getDates(dateObj.start,dateObj.end);
    //this.calDateObj.dates =  allDates;
  }

  nextMonth() {
    var new_date = moment(this.todayDate, 'YYYY-MM-DD');
    new_date.add(1, 'month');
    this.todayDate = moment(new_date).format('YYYY-MM-DD hh:mm');
    this.getRoasterFilter();
    //var dateObj = this.createCalendar(this.todayDate);
    //var allDates = this.getDates(dateObj.start,dateObj.end);
    //this.calDateObj.dates =  allDates;
  }

  getEventByDate(date: any) {
    var eventsInfo = Array();
    this.events.forEach(function (value: any) {
      var eventDate = moment(value.date).format('YYYYMMDD');
      var dateVal = moment(date).format('YYYYMMDD');
      if (eventDate == dateVal) {
        eventsInfo.push(value);
      }
    });
    return eventsInfo;
  }

  getEventData(data: any, date: any) {
    //console.log(data);
    var str = '&';
    var filter = 'vehicleId=' + data.vehicleId + '&driverId=' + data.driverId;
    var startDate = moment(new Date(date)).toISOString();
    filter += str + 'startDate=' + startDate;

    var endDate = moment(new Date(date))
      .add(23, 'hours')
      .add(59, 'minutes')
      .add(59, 'seconds')
      .toISOString();
    filter += str + 'endDate=' + endDate;

    this.getRecordsByFilter(filter);
  }

  getRoasterFilter() {
    var filter = '';
    var str = '';
    if (this.vehicleId) {
      filter += str + 'vehicleId=' + this.vehicleId;
      str = '&';
    }
    var dateObj = this.createCalendar(this.todayDate);

    if (dateObj.start) {
      var startDate = moment(new Date(dateObj.start)).toISOString();
      filter += str + 'startDate=' + startDate;
      str = '&';
    }

    if (dateObj.end) {
      var endDate = moment(new Date(dateObj.end)).toISOString();
      filter += str + 'endDate=' + endDate;
      str = '&';
    }

    this.getRoaster(filter);
  }

  getRoaster(filter) {
    this.http
      .get(baseUrl + 'api/v1/driver/getRoaster?' + filter, {
        headers: this.CommonService.getHeaders(),
      })
      .subscribe(
        (res) => {
          this.records = res['data'];
          var currentDate = this.todayDate;
          this.records.forEach((record, index) => {
            var isMonth = 'current';
            var todayMonth = moment(this.todayDate).format('M');
            var today = moment(record.scheduleDate).format('M');
            if (todayMonth > today) {
              isMonth = 'past';
            } else if (moment(this.todayDate).format('M') < today) {
              isMonth = 'future';
            }
            record['isMonth'] = isMonth;

            if (record.driverScheduleMapper.length > 0) {
              var shiftData = [];
              var moonShiftData = [];
              var isShift = false;
              var isMoonShift = false;
              record.driverScheduleMapper.forEach((driverSchedule, index) => {
                driverSchedule['icon'] = 'sun';
                var startHours = parseFloat(
                  moment(new Date(driverSchedule.shiftStartDate)).format(
                    'HH.mm'
                  )
                );
                if (startHours >= 21 || startHours < 9) {
                  driverSchedule['icon'] = 'moon';
                }

                if (driverSchedule.scheduleType == 'override') {
                  if (driverSchedule['icon'] == 'sun') {
                    shiftData.push(driverSchedule);
                    isShift = true;
                  } else {
                    moonShiftData.push(driverSchedule);
                    isMoonShift = true;
                  }
                }
              });

              if (!isShift) {
                record.driverScheduleMapper.forEach((driverSchedule, index) => {
                  if (
                    driverSchedule.scheduleType == 'offschedule' &&
                    driverSchedule['icon'] == 'sun'
                  ) {
                    shiftData.push(driverSchedule);
                    isShift = true;
                  }
                });
              }
              if (!isShift) {
                record.driverScheduleMapper.forEach((driverSchedule, index) => {
                  if (
                    driverSchedule.scheduleType == 'recurring' &&
                    driverSchedule['icon'] == 'sun'
                  ) {
                    shiftData.push(driverSchedule);
                    isShift = true;
                  }
                });
              }

              if (!isMoonShift) {
                record.driverScheduleMapper.forEach((driverSchedule, index) => {
                  if (
                    driverSchedule.scheduleType == 'offschedule' &&
                    driverSchedule['icon'] == 'moon'
                  ) {
                    moonShiftData.push(driverSchedule);
                    isMoonShift = true;
                  }
                });
              }
              if (!isMoonShift) {
                record.driverScheduleMapper.forEach((driverSchedule, index) => {
                  if (
                    driverSchedule.scheduleType == 'recurring' &&
                    driverSchedule['icon'] == 'moon'
                  ) {
                    moonShiftData.push(driverSchedule);
                    isMoonShift = true;
                  }
                });
              }
              if (moonShiftData.length > 0) {
                moonShiftData.forEach((driverSchedule, index) => {
                  shiftData.push(driverSchedule);
                });
              }
              record.driverScheduleMapper = shiftData;
            }
          });
        },
        (error) => {
          this.CommonService.autoLogout(error);
        }
      );
  }

  hideImagePopup() {
    this.istrip = false;
  }
  hideDriverPopup() {
    this.driverPopup = false;
  }
  openDriverPopup() {
    this.driverPopup = true;
    this.selectedDriverVehicle = this.selectedVehicle;
    this.driverVehicleId = this.vehicleId;
    this.clearDriverShiftForm();
  }

  getRecordsByFilter(filter) {
    this.http
      .get(baseUrl + 'api/v1/trip/getTripByFilter?' + filter, {
        headers: this.CommonService.getHeaders(),
      })
      .subscribe(
        (res) => {
          this.tripDetail = res['data'];
          this.istrip = true;
        },
        (error) => {
          this.CommonService.autoLogout(error);
        }
      );
  }

  getDriverStaff() {
    this.loadingnow = true;
    this.rosterdetailsservice.getAllDriverlist().subscribe((data) => {
      this.driverItems = data['data'];
      this.loadingnow = false;
    });
  }

  postDriverShift() {
    var shiftData = [];
    var driver1 = '';
    var driver2 = '';
    this.driverItems.forEach((driver, index) => {
      if (driver.id == this.shift1Driver) {
        driver1 = driver.name;
      }
      if (driver.id == this.shift2Driver) {
        driver2 = driver.name;
      }
    });

    if (
      this.driverVehicleId &&
      this.shift1Driver &&
      this.shift1FromDate &&
      this.shift1ToDate
    ) {
      var shiftInfo = {
        driverId: this.shift1Driver,
        vehicleId: this.driverVehicleId,
        shiftStartDate: new Date(this.shift1FromDate).toISOString(),
        shiftEndDate: new Date(this.shift1ToDate).toISOString(),
        scheduleType: this.shift1Recurring ? 'recurring' : 'override',
        driverName: driver1,
        shiftOverride: this.shift1Recurring ? false : true,
		shift:"day"
      };

      shiftData.push(shiftInfo);
    }

    if (
      this.driverVehicleId &&
      this.shift2Driver &&
      this.shift2FromDate &&
      this.shift2ToDate
    ) {
      var shiftInfo2 = {
        driverId: this.shift2Driver,
        vehicleId: this.driverVehicleId,
        shiftStartDate: new Date(this.shift2FromDate).toISOString(),
        shiftEndDate: new Date(this.shift2ToDate).toISOString(),
        scheduleType: this.shift2Recurring ? 'recurring' : 'override',
        driverName: driver2,
        shiftOverride: this.shift2Recurring ? false : true,
		shift:"night"
      };

      shiftData.push(shiftInfo2);
    }

    if (shiftData && shiftData.length == 0) {
      alert('Please fill required data.');
    } else {
      this.http
        .post(baseUrl + 'api/v1/driver/reschedule/driverShift', shiftData, {
          headers: this.CommonService.getHeaders(),
        })
        .subscribe(
          (res) => {
            var response = res['data'];
            this.vehicleId = this.driverVehicleId;
            var new_date = moment(new Date(), 'YYYY-MM-DD');
            this.todayDate = moment(new_date).format('YYYY-MM-DD hh:mm');
            this.getRoasterFilter();
            alert('Driver allocation completed successfully.');
            this.driverPopup = false;
          },
          (error) => {
            this.CommonService.autoLogout(error);
          }
        );
    }
  }

  clearDriverShiftForm() {
    this.shift1Driver = null;
    this.shift1FromDate = null;
    this.shift1ToDate = null;
    this.shift1Recurring = null;

    this.shift2Driver = null;
    this.shift2FromDate = null;
    this.shift2ToDate = null;
    this.shift2Recurring = null;
  }

  timeStringToFloat(time: any) {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    var timeStr = hours + minutes / 60;
    return timeStr;
  }
  toggleDayFirst(event) {
    // console.log(event,"hg");
    this.toggle_shift = !this.toggle_shift;
    console.log(this.toggle_shift);
  }
  toggleDaySecond(event) {
    // console.log(event,"hg");
    this.toggle_shift = !this.toggle_shift;
    console.log(this.toggle_shift);
  }
}
