import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClearInputComponent } from './clear-input/clear-input.component';
import { InputErrorComponent } from './input-error/input-error.component';
import { NoDataComponent } from './no-data/no-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { AppInputComponent } from './app-input/app-input.component';
import { AppNgselectComponent } from './app-ngselect/app-ngselect.component';
import { AppAutocompleteComponent } from './app-autocomplete/app-autocomplete.component';
import { AppSelectComponent } from './app-select/app-select.component';
import { AppTextareaComponent } from './app-textarea/app-textarea.component';
import { AppDatepickerComponent } from './app-datepicker/app-datepicker.component';
import { AppTimepickerComponent } from './app-timepicker/app-timepicker.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';



@NgModule({
  declarations: [
    AppInputComponent,
    AppNgselectComponent,
    AppAutocompleteComponent,
    AppSelectComponent,
    ClearInputComponent,
    InputErrorComponent,
    NoDataComponent,
    AppTextareaComponent,
    AppDatepickerComponent,
    AppTimepickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxSmartModalModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
  ],
  exports: [
    FormsModule,
    AppInputComponent,
    AppNgselectComponent,
    AppAutocompleteComponent,
    AppSelectComponent,
    ClearInputComponent,
    InputErrorComponent,
    NoDataComponent,
    NgSelectModule,
    AppTextareaComponent,
    NgxSmartModalModule,
    AppDatepickerComponent,
    AppTimepickerComponent,
    BsDatepickerModule,
    TimepickerModule
  ]
})
export class AppInputsModule { }
