import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CardDetailsManagerService } from 'src/app/services/card-details-manager.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'fuel-details',
  templateUrl: './fuel-details.component.html',
  styleUrls: ['./fuel-details.component.scss']
})
export class FuelDetailsComponent implements OnInit {
  @Input() activated: boolean = false;
  allData;
  u;
  constructor(public cdman:CardDetailsManagerService,public cs:CommonService) { }

  ngOnInit(): void {
  }

  closeCard()
  {
    this.cdman.dismiss('fuelDetails');
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.activated)
    {
      this.allData = this.cdman.getModalData()['data'];
      console.log(this.allData,"this.allData",this.cdman.getModalData()['obj'])
    }

  }

  showimgs()
{
  let unique = [...new Set(this.cdman.getModalData()['obj']['tripDocumentList'])];
  console.log(unique)

  // unique.forEach(u=>{
    // window.open(u,'_blank');
  // })
  
}


}
