import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.css']
})
export class NoDataComponent implements OnInit {
    @Output()
    buttonClick : EventEmitter<any> = new EventEmitter();

    @Input()
    message : any;

    @Input()
    buttonText : any;

    @Input()
    showButton = true;

    @Input()
    image : any;

  constructor() { }

  ngOnInit() {
  }

}
