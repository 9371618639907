import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CardDetailsManagerService } from 'src/app/services/card-details-manager.service';
import { baseUrl } from 'src/app/config/env-config';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'fuel-expense',
  templateUrl: './fuel-expense.component.html',
  styleUrls: ['./fuel-expense.component.scss']
})
export class FuelExpenseComponent implements OnInit {
  @Input() data: any;
  constructor(public cdman:CardDetailsManagerService,private http: HttpClient,public cs:CommonService) { }
  expenseList = [];
  ngOnInit(): void {
  }

  openDetailView(a)
  {
    this.cdman.setActive('fuelDetails',{data:a,obj:this.data});
  }

  ngOnChanges(changes: SimpleChanges) {
    let uniqExpenses = Array.from(new Set(this.data.expenseList));
    console.log(uniqExpenses,"fuelexpen")
    this.expenseList = [];
    let reqs = []
    uniqExpenses.map(e=>{
        reqs.push(this.getExpenseById(e))  
    })


forkJoin(reqs).subscribe((response)=>{
  this.expenseList = response.map((r)=>r['data']);
  console.log(this.expenseList,"this.expenseList")
});
    
}

getExpenseById(id) {
  return this.http.get(baseUrl+"api/v1/expense/"+id)
}

}
