import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SharedModule} from './shared/shared.module'
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { RosterDetailsService } from '../app//services/roster-details.service';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { DashComponent } from '../app/modules/dashboard-module/dash/dash.component';
import { RoasterComponent } from '../app/modules/roaster-module/roaster/roaster.component';
import { LoginComponent } from '../app/modules/login-module/login/login.component';
import { ProblemComponent } from '../app/modules/dashboard-module/boarditems/problem/problem.component';
import { RoundAlertComponent } from '../app//modules/dashboard-module/boarditems/round-alert/round-alert.component';
// import {AutocompleteLibModule} from 'angular-ng-autocomplete';

import { HeaderTabComponent } from '../app/shared/modules/header-tab/header-tab.component';
import { FooterTabComponent } from '../app/shared/modules/footer-tab/footer-tab.component';
import { StickyItemComponent } from './modules/dashboard-module/boarditems/sticky-item/sticky-item.component';
import { TripCardComponent } from './modules/dashboard-module/boarditems/trip-card/trip-card.component';
import { FuelExpenseComponent } from './modules/dashboard-module/boarditems/fuel-expense/fuel-expense.component';
import { CallInfoComponent } from './modules/dashboard-module/boarditems/call-info/call-info.component';
import { ShiftEndComponent } from './modules/dashboard-module/boarditems/shift-end/shift-end.component';
import { ProblemDetailsComponent } from './modules/dashboard-module/boardModals/problem-details/problem-details.component';
import { RecordsComponent } from './modules/record-module/records/records.component';
import { LogsComponent } from './modules/logs-module/logs/logs.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FuelDetailsComponent } from './modules/dashboard-module/boardModals/fuel-details/fuel-details.component';
import { EditRecordsComponent } from './modules/record-module/recordsModals/edit-records/edit-records.component';
import { BlueCardDetailsComponent } from './modules/dashboard-module/boardModals/blue-card-details/blue-card-details.component';
import { TrackComponent } from './track-module/track/track.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
// import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgSearchFilterModule } from 'ng-search-filter';
import { FormsModule } from '@angular/forms';
import { DownloadHeaderComponent } from './download-header/download-header.component';
import { AgmCoreModule } from '@agm/core';
import { EmployeeTrackComponent } from './track-module/employeetrack/employeetrack.component';
import { DriverComponent } from './modules/driver-module/driver.component';



import { TimeagoPipe } from './track-module/timeago.pipe';
import { NotesComponent } from './modules/notes-module/notes.component';
import { SummaryComponent } from './modules/summary-module/summary.component';
// import { AutocompleteLibModule } from 'angular-ng-autocomplete';
// import { AgmCoreModule } from '@agm/core';
// import {TimeAgoPipe} from 'time-ago-pipe';
// import {TimeAgoPipe} from 'time-ago-pipe'
//import { Bootstrap4FrameworkModule } from 'angular6-json-schema-form';

// import 'ngx-toastr/toastr';

// import {MatAutocompleteModule,MatInputModule} from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    DashComponent,
    RoasterComponent,
    LoginComponent,
    ProblemComponent,
    RoundAlertComponent,
    StickyItemComponent,
    TripCardComponent,
    CallInfoComponent,
    FuelExpenseComponent,
    ShiftEndComponent,
    ProblemDetailsComponent,
    HeaderTabComponent,
    FooterTabComponent,
    RecordsComponent,
    LogsComponent,
    FuelDetailsComponent,
    EditRecordsComponent,
    BlueCardDetailsComponent,
    TrackComponent,
    Ng2SearchPipeModule,
    TrackComponent,
    DownloadHeaderComponent,
    TimeagoPipe,
    EmployeeTrackComponent,
    // TimeAgoPipe
    DriverComponent,
    NotesComponent,
    SummaryComponent,
   
  ],
  imports: [
    BrowserModule,
    FormsModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyCd3zLpJd5zkTj7nppt3PFJauvo1331gYI'
    // }),
    SharedModule,
    AppRoutingModule,
    // MatAutocompleteModule,
    // MatInputModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSmartModalModule.forRoot(),
	  NgMultiSelectDropDownModule.forRoot(),
    NgSearchFilterModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCd3zLpJd5zkTj7nppt3PFJauvo1331gYI',
      libraries: ['places']

    })
  
    // TimeAgoPipe
 
  
  
  // 45987be4c79e14071e28
    //Bootstrap4FrameworkModule
  ],
  providers: [RosterDetailsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
