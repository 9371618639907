import { Component } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Vehicle, VehicleAdapter } from "./models/vehicle.model";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CommonService } from './services/common.service';
import{baseUrl} from'../app/config/env-config';
import * as $ from 'jquery';
window["$"] = $;
window["jQuery"] = $;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
 
  constructor(private http: HttpClient, private adapter: VehicleAdapter,public cs:CommonService,) {

      this.cs.getMasters();
}



list(): Observable<Vehicle[]> {
// const url = "http://DriverAppLB-587246265.ap-south-1.elb.amazonaws.com/api/v1/vehicle/getAllVehicles";
return this.http.get(baseUrl+"api/v1/vehicle/getAllVehicles").pipe(
  // Adapt each item in the raw data array
  map((data: any[]) => data['data'].map((item) => this.adapter.adapt(item)))
);
}



}
