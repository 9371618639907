import { Injectable } from "@angular/core";
import { Adapter } from "./adapter";

export class RoasterObj {
    constructor(
        public id:                  String,
        public driverId:            String,
        public scheduleDate:        String,
        public slotList:            [SlotList],
        public vehicleId:           String,
        public createdAt:           String,
        public updatedAt:           String
    ) {}
  }


  export class  SlotList {
    constructor(
      public driverId:            String,
      public driverName:          String,
      public fromTime:            String,
      public helperId:            String,
      public helperName:          String,
      public toTime:              String,
      public tripId:              String,          
      public createdAt:           String,
      public updatedAt:           String,
      public scheduleDate:        String
    ){}
  }
  @Injectable({
    providedIn: "root",
  })

  export class RoasterObjAdapter implements Adapter<RoasterObj> {
    
    adapt(item: any): RoasterObj {
      console.log("PRRRRRRRRRR",item)
      return new RoasterObj(
        item.id, 
        item.driverId   ,
        item.scheduleDate,
        item.slotList.map(x=> new SlotList(
          x.driverId,
          x.driverName,
          x.fromTime,
          x.helperId,
          x.helperName,
          x.toTime,
          x.tripId,   
          x.createdAt,
          x.updatedAt,
          x.scheduleDate         
         )
         ),
         item.vehicleId,
         item.createdAt,       
         item.updatedAt,        
        
      )
    }
}