import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CardDetailsManagerService } from 'src/app/services/card-details-manager.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'trip-card',
  templateUrl: './trip-card.component.html',
  styleUrls: ['./trip-card.component.scss']
})
export class TripCardComponent implements OnInit {
  @Input() data: any;
  constructor(public cdman:CardDetailsManagerService,public cs:CommonService) { }

  ngOnInit(): void {
  }

  openDetailView()
  {
    this.cdman.setActive('bcDetails',this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log('tripcrd',this.data)
}

}
