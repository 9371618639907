import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { baseUrl } from "src/app/config/env-config";
import { CommonService } from "src/app/services/common.service";
import { saveAs } from 'file-saver';


@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
})

export class SummaryComponent implements OnInit {
    summarytype: any = 'driveroustanding'
    Date: any = null;
    driveroutstandingsummary: any[] = null
    constructor(
        private router: Router,
        private http: HttpClient,
        public cs: CommonService,
        private toastr: ToastrService,
    ) { }

    ngOnInit(): void {
        let todaydate = moment(new Date()).toISOString()
        this.Date = moment(todaydate).format('YYYY-MM-DD');
        this.GetSummaryOutstanding()
    }


    GetSummaryOutstanding() {
        this.http.post(baseUrl + 'api/v1/outstanding/getOutStandingReport',
            [
                moment(this.Date).toISOString(),
                moment(this.Date).add(1, 'days').toISOString()
            ]
            , { headers: this.cs.getHeaders() })
            .subscribe(
                (res) => {
                    // console.log(res['data'], "res");
                    // this.driveroutstandingsummary = res['data']
                    var summaryinfo = [];
                    var info = {};
                    res['data'].forEach((summary, index) => {
                        info = {
                            ...summary,
                            'collectedAmountDate': Object.keys(summary.collectedAmountDateWise)[0],
                            'collectedAmountvalue': Object.values(summary.collectedAmountDateWise)[0],
                            'depositedAmountDate': Object.keys(summary.depositedAmountDateWise)[0],
                            'depositedAmountvalue': Object.values(summary.depositedAmountDateWise)[0],
                        }
                        // console.log(info, "info");
                        summaryinfo.push(info)
                    })
                    this.driveroutstandingsummary = summaryinfo
                },
                (error) => {
                    console.log(error);
                }
            )
    }


    Applychanges() {
        // console.log(this.driveroutstandingsummary, "summaryinfo");
        // console.log(this.summarytype, "summarytype");
        this.GetSummaryOutstanding()

    }

    Downloadcsv() {
        var summaryinfo = [];
        var info = {};
        this.driveroutstandingsummary.forEach((summary, index) => {
            info = {
                'Driver Name': summary.driverName,
                'Vehicle Number': summary.vehicleNumber,
                'Location': summary.location,
                'Collected Cash': Object.values(summary.collectedAmountDateWise)[0] || '0',
                'Deposited Amount': Object.values(summary.depositedAmountDateWise)[0] || '0',
                'Pending O/S': summary.totalOutStanding || '0',
                'Last Deposited Date': this.changeDateFormat(summary.lastDeposited)
            }
            // console.log(info, "info");
            summaryinfo.push(info)
        })
        this.downloadFile(summaryinfo)
    }

    downloadFile(data: any) {
        const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
        const header = Object.keys(data[0]);
        let csv = data.map((row) =>
            header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(',')
        );
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');

        var blob = new Blob([csvArray], { type: 'text/csv' });
        saveAs(
            blob,
            'Driveroutstandingsummary' + '_' + this.changeDateFormat(new Date()) + '.csv'
        );
    }

    changeDateFormat(date: any) {
        if (date) {
            return moment(new Date(date)).format('DD/MM/yyyy');
        }
    }

}
