import { Component, OnInit, Input, forwardRef, Self, Optional, Host, EventEmitter, Output, Injector } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NgControl, NG_VALIDATORS, ControlContainer, AbstractControl, Validators } from '@angular/forms';
import { validator } from 'src/app/shared/utils/validator';

@Component({
  selector: 'app-input',
  templateUrl: './app-input.component.html',
  styleUrls: ['./app-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AppInputComponent,
      multi: true
    }
  ]
})
export class AppInputComponent implements OnInit, ControlValueAccessor {

  @Input() name: string;
  @Input() val: string;
  @Input() noGrid: boolean = false;

  @Input() label;
  @Input() labelSmall;

  @Input() errorMsg = "Invalid Input";
  @Input() errorHint;
  @Input() clearable = true;

  @Input() placeholder = "";

  @Input() min = null;
  @Input() max = null;

  @Input() minLength = null;
  @Input() maxLength = null;

  @Input() srcImage = null;
  @Input() srcTitle = null;

  @Input() disabled = false;

  customValidators = new validator();

  inputType = "text";
  private _type;
  get type(): any {
    return this._type;

  }
  @Input() set type(val: any) {
    this._type = val;
    // this.inputType
  }

    // Both onChange and onTouched are functions
    onValChange: any = () => { };
    onValTouched: any = () => { };

    @Output()
    onBlur: EventEmitter<any> = new EventEmitter();

    @Output()
    onChange: EventEmitter<any> = new EventEmitter();

    @Output()
    onTouched: EventEmitter<any> = new EventEmitter();

    @Input() formControlName: string;
    public control: AbstractControl;

    get value() {
      return this.val;
    }

    set value(val) {
      this.val = val;
      this.onChange.emit(val);
      this.onValChange(val);
      this.onValTouched();
    }

  constructor(private injector: Injector) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.control = ngControl.control as FormControl;
        this.addValidators(this.type);
      });
    } else {
      // Component is missing form control binding
        // console.warn('Missing FormControlName directive from host element of the component');
    }
  }

  addValidators(type) {
    if(type == "website" || type == "url") {
      this.control.setValidators([this.control.validator, this.customValidators.validateWebsite]);
    }
    if(type == "email") {
      this.control.setValidators([this.control.validator, this.customValidators.validateEmail]);
    }
    if(type == "otp") {
      this.control.setValidators([this.control.validator, this.customValidators.validOtp]);
    }
    if(type == "pincode") {
      this.control.setValidators([this.control.validator, this.customValidators.validPinCode]);
    }
    if(type == "latlong") {
      this.control.setValidators([this.control.validator, this.customValidators.validLatitudeLongitude]);
    }
    if(type == "dob") {
      this.control.setValidators([this.control.validator, this.customValidators.validateDOB]);
    }
    if(type == "number") {
      this.control.setValidators([this.control.validator, this.customValidators.validStringPositiveNumber]);
    }
    if(type == "mobile") {
      this.control.setValidators([this.control.validator, this.customValidators.validMobile]);
    }
    if(type == "username") {
      this.control.setValidators([this.control.validator]);
    }

    this.control.updateValueAndValidity();

    if(this.min) {
      this.control.setValidators([this.control.validator, Validators.min(this.min)]);
    }
    if(this.max) {
      this.control.setValidators([this.control.validator, Validators.min(this.max)]);
    }
    if(this.minLength) {
      this.control.setValidators([this.control.validator, Validators.minLength(this.minLength)]);
    }
    if(this.maxLength) {
      this.control.setValidators([this.control.validator, Validators.maxLength(this.maxLength)]);
    }
    this.control.updateValueAndValidity();

  }

  validate({ value }: FormControl) {
    return null;
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onValChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  //callback function passed by the forms API
  registerOnTouched(fn) {
    this.onValTouched = fn;
  }
  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    // if (value) {
      this.value = value;
    // }
  }

}
