import {
  Component
} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { baseUrl, employeeUrl } from 'src/app/config/env-config';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';





@Component({
  selector: 'app-track',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss'],
})
export class DriverComponent {
  driverdetailspopup: boolean = false;
  username: string = '';
  password: string = '';
  createduser: string = '';
  FormData: {
    firstname: string;
    lastname: string;
    email: string;
    mobile: number;
    gender: string;
    age: string;
    employeeId: string;
    userType: string;
    driverStatus: string;
    employeetype: string;
  } = {
      firstname: '', lastname: '', email: '', mobile: null,
      gender: '',
      age: '',
      employeeId: '',
      userType: 'driver',
      driverStatus: 'live',
      employeetype: '',
    };
  validation: {
    email: boolean;
    mobile: boolean;
  } = {
      email: false,
      mobile: false
    };
  employeeTypeArray: any[]


  constructor(
    private router: Router,
    private http: HttpClient,
    public cs: CommonService,
    private toastr: ToastrService,
  ) {
    this.http
      .get(employeeUrl + 'api/v1/config/getConfig', {
        headers: this.cs.getHeaders(),
      })
      .subscribe(
        (res) => {
          var response = res['data'];
          console.log(response, "response");
          this.employeeTypeArray = response?.employeeType;
        },
        (error) => {
          console.log(error.error.meta.msg, "error", error);
          this.toastr.error(error?.error?.meta?.msg);
        }
      );
  }
  assignVendorFun() {
    if (this.FormData.firstname && this.FormData.employeeId && this.FormData.age && this.FormData.gender && this.FormData.email && this.FormData.mobile != null && this.FormData.employeetype) {
      this.validatePhoneNumber(this.FormData.mobile)
      this.validateEmail(this.FormData.email)
      if (this.validation.email && this.validation.mobile) {
        this.createDriver()
      }
    } else {
      this.toastr.error("Please enter all details");
    }

  }
  redirectToPrev() {
    this.router.navigate(['team']);
  }
  validateEmail(email: any) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      this.validation.email = false;
      this.toastr.error("Please enter valid email")
    } else {
      this.validation.email = true
    }
  }
  validatePhoneNumber(mobile: any) {
    const phoneNumberRegex = /^[0-9]{10}$/; // Assumes 10-digit phone number format
    if (!phoneNumberRegex.test(mobile)) {
      this.validation.mobile = false;
      this.toastr.error("Please enter valid mobile number")
    } else {
      this.validation.mobile = true
    }
  }
  createDriver() {
    if (this.FormData.employeetype == "driver") {
      let data = {
        name: this.FormData.firstname + ' ' + this.FormData.lastname,
        email: this.FormData.email,
        gender: this.FormData.gender,
        age: this.FormData.age,
        mobile: this.FormData.mobile,
        employeeId: this.FormData.employeeId,
        userType: this.FormData.employeetype,
        driverStatus: "live"
      }
      this.http
        .post(baseUrl + 'api/v1/driver/create', data, {
          headers: this.cs.getHeaders(),
        })
        .subscribe(
          (res) => {
            var response = res['meta'];
            // console.log(response, "response", res['data']);
            if (response?.statusCode) {
              this.toastr.success("Driver added successfully");
              this.driverdetailspopup = true
              this.createduser = "Driver"
              this.username = res['data'].userId
              this.password = res['data'].userId
              // window.location.reload();
            }
          },
          (error) => {
            console.log(error?.error?.meta?.msg, "error", error);
            this.toastr.error(error?.error?.meta?.msg);
          }
        );
    } else {
      let data = {
        firstName: this.FormData.firstname,
        lastName: this.FormData.lastname,
        email: this.FormData.email,
        mobileNumber: this.FormData.mobile,
        employeeId: this.FormData.employeeId,
        reportingManagerId: null,
        employeeType: this.FormData.employeetype,
      }
      this.http
        .post(employeeUrl + 'employee/createEmployee', data, {
          headers: this.cs.getHeaders(),
        })
        .subscribe(
          (res) => {
            var response = res['meta'];
            // console.log(response, "response", res);
            if (response?.statusCode) {
              this.toastr.success("Employee added successfully");
              this.driverdetailspopup = true
              this.createduser = "Employee"
              this.username = res['data'].userId
              this.password = res['data'].userId
              // window.location.reload();
            }
          },
          (error) => {
            console.log(error?.error?.meta?.msg, "error", error);
            this.toastr.error(error?.error?.meta?.msg);
          }
        );
    }
  }

  Copyandclose() {

    const combinedText = "Username : " + this.username + "\nPassword : " + this.password;

    // Create a textarea element to hold the combined text
    // const textarea = document.createElement('textarea');
    // textarea.value = combinedText;

    // // Append the textarea to the document
    // document.body.appendChild(textarea);

    // // Select and copy the text to the clipboard
    // textarea.select();
    // document.execCommand('copy');

    // // Remove the temporary textarea
    // document.body.removeChild(textarea);

    if (navigator.clipboard) {
      navigator.clipboard.writeText(combinedText)
        .then(() => {
          this.toastr.success('Details copied successfully.');
          console.log('Text copied to clipboard successfully.');
          this.driverdetailspopup = false
          // window.location.reload();
          this.redirectToPrev()
        })
        .catch((error) => {
          console.error('Copy to clipboard failed');
          this.toastr.error('Copy to clipboard failed');
          this.driverdetailspopup = false
          // window.location.reload();
          this.redirectToPrev()

        });
    } else {
      console.error('Something went wrong !!!');
      this.driverdetailspopup = false
      // window.location.reload();
      this.redirectToPrev()
    }


  }
  Closepopup() {
    this.driverdetailspopup = false
    // window.location.reload();
    this.redirectToPrev()
  }

}
