import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashComponent } from '../app/modules/dashboard-module/dash/dash.component';
import { HeaderTabComponent } from '../app/shared/modules/header-tab/header-tab.component';
import { LoginComponent } from '../app/modules/login-module/login/login.component';
import { RecordsComponent } from '../app/modules/record-module/records/records.component';
import { LogsComponent } from '../app/modules/logs-module/logs/logs.component';
import { RoasterComponent } from '../app/modules/roaster-module/roaster/roaster.component';
import { AuthGuardService } from './services/auth-guard.service';
import { TrackComponent } from './track-module/track/track.component';
import { EmployeeTrackComponent } from './track-module/employeetrack/employeetrack.component';
import { DriverComponent } from './modules/driver-module/driver.component';
import { NotesComponent } from './modules/notes-module/notes.component';
import { SummaryComponent } from './modules/summary-module/summary.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  { path: 'dash', component: DashComponent, canActivate: [AuthGuardService], data: { disallowedRoles: ['ACCOUNTANT'] } },
  { path: 'roaster', component: RoasterComponent, canActivate: [AuthGuardService], data: { disallowedRoles: ['ACCOUNTANT'] } },
  { path: 'records', component: RecordsComponent, canActivate: [AuthGuardService] },
  { path: 'team', component: TrackComponent, canActivate: [AuthGuardService], data: { disallowedRoles: ['ACCOUNTANT'] } },
  { path: 'logs', component: LogsComponent, canActivate: [AuthGuardService], data: { disallowedRoles: ['ACCOUNTANT'] } },
  { path: 'track', component: EmployeeTrackComponent, canActivate: [AuthGuardService], data: { disallowedRoles: ['ACCOUNTANT'] } },
  { path: 'add-team', component: DriverComponent, canActivate: [AuthGuardService], data: { disallowedRoles: ['ACCOUNTANT'] } },
  { path: 'notes', component: NotesComponent, canActivate: [AuthGuardService], data: { disallowedRoles: ['ACCOUNTANT'] } },
  { path: 'summary', component: SummaryComponent, canActivate: [AuthGuardService], data: { disallowedRoles: ['ACCOUNTANT'] } },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
