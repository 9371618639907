import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const disallowedRoles = next.data.disallowedRoles;
    if (localStorage.getItem('currentUser')) {
      const userRole = this.getUserRole();
      console.log(disallowedRoles, 'role dis',disallowedRoles && disallowedRoles.includes(userRole));

      if (disallowedRoles && disallowedRoles.includes(userRole)) {
        // Redirect to an unauthorized page or handle the case as needed
        console.log(userRole, "role unauth");
        this.router.navigate(['/records']);
        return false;
      } else {
        console.log(userRole, "role auth");
        // User is allowed to access the route
        return true;
      }
    }
    this.router.navigate(['']);
    console.log("role not logged in");
    return false;
  }

  private getUserRole(): string {
    let user = JSON.parse(localStorage.getItem('currentUser'))['data']['roles'].toString()
    return user;
  }
}
