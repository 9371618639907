import { AppInputsModule } from './modules/app-inputs/app-inputs.module';
import { ImagePreloadDirective } from './directives/image-preload.directive';
import { OnInitDirective } from './directives/on-init.directive';
import { NgModule } from '@angular/core';
import { SearchPipe } from './pipes/search.pipe';
import { ConfirmationDialogComponent } from './modules/confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogService} from './modules/confirmation-dialog/confirmation-dialog.service'


const components = [
  
];

const directives = [
    ImagePreloadDirective,
    OnInitDirective
];

const pipes = [
    SearchPipe
];

const modules = [
  AppInputsModule,
];

@NgModule({
    imports: [
        ...modules,
    ],
    exports: [
        ...directives,
        ...pipes,
        ...modules,
        ...components,
    ],
    declarations: [
        ...directives,
        ...pipes,
        ...components,
        ConfirmationDialogComponent,
    ],
    providers:[ConfirmationDialogService]
})
export class SharedModule { }
