import { Injectable } from "@angular/core";
import { Adapter } from "./adapter";

export class DashObj {
    constructor(
        public id:                  String,
        public vehicleDetails:      VehicleDetails,
        public driverDetails:       DriverDetails,
        public driverLiveTime:      String,
        public tripStatus:          String,
        public driverStatus:        String,
        public amount:              Number,
        public distance:            String,
        public estimatedTripTime:   String,
        public tripFromLocation:    String,
        public tripToLocation:      String,
        public startLongitude:      String,
        public endLongitude:        String,
        public startLatitude:       String,
        public endLatitude:         String,
        public tripStartTime:       String,
        public tripEndTime:         String,
        public tripComments:        String,
        public payment:             Payment,
        public breakDown:           any,
        public assignedByName:      String,
        public expenseList:         [String],
        public tripDocumentList:    [String],
        public driverActionList:    [DriverActionList],
        public createdAt:           String,
        public updatedAt:           String
    ) {}
  }


  export class Payment {
    constructor(
       public id: String,
       public paymentType: String,
       public amount: Number,
       public tripId: String,
       public driverId: String,
       public vehicleId: String,
       public paymentDocumentList: [String],
       public createdAt: String,
       public updatedAt: String
    ){}
  }


  export class  DriverActionList {
    constructor(
       public id:         String,
       public actionType: String,
       public actionTime: String,
       public createdAt:  String,
       public updatedAt:  String
    ){}
  }

  export class  DriverDetails {
    constructor(
      public id :            String,
      public name :          String,
      public email :         String,
      public password :      String,
      public gender :        String,
      public age :           String,
      public mobile :        String,
      public userType :      String,
      public driverStatus :  String,
      public createdAt :     String,
      public updatedAt :     String
    ){}
  }



  export class  VehicleDetails {
    constructor(
            public id:                       String,
            public vehicleNumber:            String,
            public vehicleType:              String,
            public vehicleModel:             String,
            public serviceYear:              String,
            public partnerId:                String,
            public ambulanceType:            String,
            public vehicleCategory:          String,
            public vehicleParkLocation:      String,
            public vehicleLocationAddress:   String,
            public vehicleLocationLongitude: String,
            public vehicleLocationLatitude:  String,
            public createdAt:                String,
            public updatedAt:                String
    ){}
  }


  export class  BreakDown {
    constructor(
          public id:                     String,
          public vehicleId:              String,
          public vehicleNumber:          String,
          public driverId:               String,
          public driverMobile:           String,
          public problem:                String,
          public comment:                String,
          public vendorDetails:          VendorDetails,
          public breakDownStatus:        String,
          public breakDownCost:          String,
          public timeToFix:              String,
          public resolved:               String,
          public driverActionList:       String,
          public breakDownDocumentList:  String,
          public createdAt:              String,
          public updatedAt:              String
    ){}
  }
  
  export class  VendorDetails {
    constructor(
      public id :                    String,
      public name :                  String,
      public vendorPhoneNumber :     String,
      public alternatePhoneNumber :  String,
      public vendorAddress :         String,
      public vendorType :            String,
      public vendorCostEstimation :  String,
      public vendorTimeEstimation :  String,
      public createdAt :             String,
      public updatedAt :             String
    ){}
  }


  

  



  @Injectable({
    providedIn: "root",
  })

  export class DashObjAdapter implements Adapter<DashObj> {
    adapt(item: any): DashObj {
      return new DashObj(
        item?.id || "",              
        new VehicleDetails(
          item?.vehicleDetails?.id || "",                      
          item?.vehicleDetails?.vehicleNumber || "",           
          item?.vehicleDetails?.vehicleType || "",             
          item?.vehicleDetails?.vehicleModel || "",            
          item?.vehicleDetails?.serviceYear || "",             
          item?.vehicleDetails?.partnerId || "",               
          item?.vehicleDetails?.ambulanceType || "",           
          item?.vehicleDetails?.vehicleCategory || "",         
          item?.vehicleDetails?.vehicleParkLocation || "",     
          item?.vehicleDetails?.vehicleLocationAddress || "",  
          item?.vehicleDetails?.vehicleLocationLongitude || "",
          item?.vehicleDetails?.vehicleLocationLatitude || "", 
          item?.vehicleDetails?.createdAt || "",               
          item?.vehicleDetails?.updatedAt   || ""             
        ) || null,  
        // new DriverDetails(
        //   item?.driverDetails.id,
        //   item?.driverDetails.name,
        //   item?.driverDetails.email,
        //   item?.driverDetails.password,
        //   item?.driverDetails.gender,
        //   item?.driverDetails.age,
        //   item?.driverDetails.mobile,
        //   item?.driverDetails.userType,
        //   item?.driverDetails.driverStatus,
        //   item?.driverDetails.createdAt,
        //   item?.driverDetails.updatedAt
        // ) || null
        item?.driverDetails || "",    
        item?.driverLiveTime || "",   
        item?.tripStatus || "",       
        item?.driverStatus || "",     
        item?.amount || "",           
        item?.distance || "",         
        item?.estimatedTripTime || "", 
        item?.tripFromLocation || "", 
        item?.tripToLocation || "",   
        item?.startLongitude || "",   
        item?.endLongitude || "",     
        item?.startLatitude || "",    
        item?.endLatitude || "",      
        item?.tripStartTime || "",    
        item?.tripEndTime || "",      
        item?.tripComments || "",     
            new Payment(
                item?.payment?.id || "",
                item?.payment?.paymentType || "",
                item?.payment?.amount || "",
                item?.payment?.tripId || "",
                item?.payment?.driverId || "",
                item?.payment?.vehicleId || "",
                item?.payment?.paymentDocumentList || "",
                item?.payment?.createdAt || "",
                item?.payment?.updatedAt || ""),      
                (item.breakDown) ?    
        new BreakDown(
          item?.breakDown?.id || "",                             
          item?.breakDown?.vehicleId || "",                      
          item?.breakDown?.vehicleNumber || "",                  
          item?.breakDown?.driverId || "",                       
          item?.breakDown?.driverMobile || "",                   
          item?.breakDown?.problem || "",                        
          item?.breakDown?.comment || "",  
          new VendorDetails(
                item?.breakDown?.vendorDetails?.id || "",                   
                item?.breakDown?.vendorDetails?.name || "",                 
                item?.breakDown?.vendorDetails?.vendorPhoneNumber || "",    
                item?.breakDown?.vendorDetails?.alternatePhoneNumber || "", 
                item?.breakDown?.vendorDetails?.vendorAddress || "",        
                item?.breakDown?.vendorDetails?.vendorType || "",           
                item?.breakDown?.vendorDetails?.vendorCostEstimation || "", 
                item?.breakDown?.vendorDetails?.vendorTimeEstimation || "", 
                item?.breakDown?.vendorDetails?.createdAt || "",            
                item?.breakDown?.vendorDetails?.updatedAt || "",            
          ),                                    
          item?.breakDown?.breakDownStatus || "",                
          item?.breakDown?.breakDownCost || "",                  
          item?.breakDown?.timeToFix || "",                      
          item?.breakDown?.resolved || "",                       
          item?.breakDown?.driverActionList || "",               
          item?.breakDown?.breakDownDocumentList || "",          
          item?.breakDown?.createdAt || "",                      
          item?.breakDown?.updatedAt || "",                      
        ) : null,       
        item?.assignedByName || "",  
        item?.expenseList || "",     
        item?.tripDocumentList || "",
        item?.driverActionList.map(x=> new DriverActionList(
                    x?.id || "",
                    x?.actionType || "",
                    x?.actionTime || "",
                    x?.createdAt || "",
                    x?.updatedAt || ""
            )),
        item?.createdAt || "",       
        item?.updatedAt || ""
        )
    }
}