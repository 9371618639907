import {
    Component
  } from '@angular/core';
  import { EmployeeDataService } from 'src/app/employeeServices/employee-data.service';
  import {} from 'googlemaps';
  import { CommonService } from '../../services/common.service';
  import * as moment from 'moment';
  
  import { ToastrService } from 'ngx-toastr';
  import { Router, ActivatedRoute } from '@angular/router';
  
  @Component({
    selector: 'app-track',
    templateUrl: './employeetrack.component.html',
    styleUrls: ['./employeetrack.component.scss'],
  })
  export class EmployeeTrackComponent {
    employeeHistory: any[];
    date: any;
    startDate: any;
    endDate:any;
    lng: any;
    id: any;
    start: any;
    selectedEmployeeById: any;
    time: any;
    employeePastLocationCoordinates: any;
    employeePastLocationDataHistoryCoordinates: any;
    coordinates: [{ lat: any; lng: any }];
    lat = 20.5937;
    long = 78.9629;
    zoom = 6;
    detailsEmployee = '';
    userId='';
    formTo: {from: any; to: any} = { from: null, to: null };
    employeePastHistory: any[]; 
    ShowDownload = false; 
  
    constructor(
      private employeeDataHistory: CommonService,
      private locationCoordinates: CommonService,
      private toastr: ToastrService,
      private router: Router,
      private route: ActivatedRoute,
      private employeePastLocation : CommonService,
    ) {
      this.start = new Date();
      let date = Date.now();
      this.time = new Date().getTime();
      console.log(this.time, 'time');
  
      employeeDataHistory.employees().subscribe((data: any) => {
        this.employeeHistory = data.data;
        
        this.employeeHistory.forEach((e) => {
            if (this.selectedEmployeeById == e.employeeId) {
                this.detailsEmployee = e;
            }
          });

      });
    }
    markers = [];
    private employeeLocationCoordinates(
      locationCoordinates: CommonService,
      id: any
    ) {
      locationCoordinates.getLocationHistoryById(id).subscribe((data: any) => {
        this.employeePastLocationCoordinates = data;
        this.employeePastLocationDataHistoryCoordinates = data.data;
        this.markers = [
          ...this.employeePastLocationDataHistoryCoordinates?.map((e) => {
            return {
              lat: e.employeeCurrentLocation.latitude,
              lng: e.employeeCurrentLocation.longitude,
            };
          }),
        ];
        console.log(this.markers, 'markers');
      });
    }
    changeDateFormat(date: any) {
      if (date) {
        return moment(new Date(date)).format('d/MM/yyyy');
      }
    }
    changeTimeFormat(date: any) {
      if (date) {
        return moment(new Date(date)).format('h:mm a');
      }
    }
    ngOnInit() {
        this.route.queryParams.subscribe(params => {
          this.userId = params['id'];
          // You can access the value of the `id` parameter here or perform any required logic
          console.log('User ID:', this.userId);
        });
          this.selectedEmployeeById = this.userId;
          
          this.employeeLocationCoordinates(
            this.locationCoordinates, this.userId
          );
            setInterval(() => {
              window.location.reload()
            }, 180000);
      }
    redirectToPrev(){
		this.router.navigate(['team']);    
    }
    showListPdf(){
        this.formTo.from = this.startDate;
        this.formTo.to = this.endDate;
        if(this.startDate && this.endDate){
         this.ShowDownload = true;
         this.employeePastLocation.getEmployeePastLocationByID(this.userId, this.formTo).subscribe((data: any) => {
          console.log(data, "past data of data");
          this.employeePastHistory = data.data;
        }) 
        }else{
          this.toastr.error('Please select start and end date');
        }
          
    }
    openPDF(pdfUrl: any): void {
      window.open(pdfUrl, '_blank');
    }
  }
  