import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { CardDetailsManagerService } from 'src/app/services/card-details-manager.service';
import { RosterDetailsService } from 'src/app/services/roster-details.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmationDialogService } from 'src/app/shared/modules/confirmation-dialog/confirmation-dialog.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/app/config/env-config';
import { saveAs } from 'file-saver';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  selected = 'driver';
  loadingnow = false;
  listItems = [];
  dashConfig = [];
  otherexpenses = [];
  records = [];
  breakdownDetail = [];
  expenseDetail = [];
  tripDetail = [];
  outstandingDetail = [];
  expenseDocumentList = [];
  vehicleCity = [];
  isDocument = false;
  selectedVehicle:any = null;
  selectCity:any = null;
  reason:any = null;
  filterType:any = "";
  startDate:any = null;
  endDate:any = null;
  isCsv:any=false;
	dataFetched:any=false;
  activeItm:any;
  dropdownSettings:any;
  logbookDetail = [];
  groomingDetail = [];
  staffImage = [];
  vehicleExterior= [];
  vehicleInterior = [];
  
  
  
  constructor(private rosterdetailsservice: RosterDetailsService,private CommonService: CommonService,private confirmationDialogService: ConfirmationDialogService,public cm: CardDetailsManagerService,private http: HttpClient,private cdref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getAllVehiclesList();
		if(localStorage.getItem("masterdata")){
			var masterdata = JSON.parse(localStorage.getItem("masterdata"));
			//console.log(masterdata);
			if(masterdata && masterdata.dashRecord.length > 0){
				this.dashConfig = masterdata.dashRecord;
				// this.otherexpenses = masterdata.otherexpenses;
				// this.vehicleCity = masterdata.vehicleCity;
				// this.filterType = this.dashConfig[0].toLowerCase();
			}
		}else{
			this.getAllMasters();
		}
	
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'vehicleNumber',
      allowSearchFilter: true,
      enableCheckAll: false,
      maxHeight: 200,
	  	closeDropDownOnSelection: true,
    };

  }
  
  
  onItemSelect(item: any) {
    //console.log(item);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }
  
ngAfterContentChecked() {

    this.cdref.detectChanges();

  }
  // selectOpt(type)
  // {
    // console.log("type",type)
    
    // this.selected = type;
    // if(type == 'driver')
    // {
      // this.getDriverStaff();
    // }
    // else
    // if(type == 'vehicle')
    // {
      // this.getAllVehiclesList();
    // }
  // }

	changeDateFormat(date:any){
		if(date){
			return moment(new Date(date)).format('d/MM/yyyy');
		}
  }

  changeTimeFormat(date:any){
		if(date){
			return moment(new Date(date)).format('h:mm a');
		}
  }


  getAllVehiclesList(){
    this.loadingnow = true;
    this.rosterdetailsservice.getAllHelpNowVehicleStatus().subscribe(data =>{
    this.listItems = data['data'];
    // console.log("listItemsVehicle", this.listItems)
    this.loadingnow = false;
		this.listItems.forEach((item, index) => {
		  if(item.vehicleModel){
				item.vehicleNumber += " "+item.vehicleModel;
		  }
		  if(item.baseLocation){
				item.vehicleNumber += " "+item.baseLocation;
		  }
		});
    }, error => {
			this.CommonService.autoLogout(error);
		});
  }
  
  getVehicles(){
	  this.selectedVehicle = null;
		if(this.selectCity && this.selectCity !== 'null' ){
			this.getVehiclesListByCity();
		}else{
			this.getAllVehiclesList()
		}
  }
  
  getVehiclesListByCity(){
    this.loadingnow = true;
    this.rosterdetailsservice.getVehiclesListByCity(this.selectCity).subscribe(data =>{
     this.listItems = data['data'];
     //console.log("listItemsVehiclecity", this.listItems)
     this.loadingnow = false;
		this.listItems.forEach((item, index) => {
		  if(item.vehicleModel)
		  {
			item.vehicleNumber += " "+item.vehicleModel;
		  }
		  
		  if(item.baseLocation)
		  {
			item.vehicleNumber += " "+item.baseLocation;
		  }
		});
    }, error => {
		this.CommonService.autoLogout(error);
	});
  }

	getAllMasters(){
		this.loadingnow = true;
		this.CommonService.getAllMasters().subscribe(data =>{
			var masterdata = data['data'];
			localStorage.setItem("masterdata", JSON.stringify(masterdata));
			this.dashConfig = masterdata.dashRecord;
			// this.otherexpenses = masterdata.otherexpenses;
			// this.filterType = this.dashConfig[0].toLowerCase();
			this.loadingnow = false;
		}, error => {
			this.CommonService.autoLogout(error);
		});
	}

	downloadCsv(){
		this.isCsv = true;
		this.recordFilter();
	}

	recordFilter(){
		var filter = "";
		var str = "";

		if(this.selectedVehicle && this.selectedVehicle.length > 0){
			let vehicleNumber = this.selectedVehicle[0].vehicleNumber.split(" ")[0];
			filter += str+"vehicleNumber="+vehicleNumber;
			str = "&";
		}
		// else{
			// filter += str+"vehicleId="+null;
			// str = "&";
		// }
		
		
		// if(this.filterType){
		// 	filter += str+"filterType="+this.filterType;
		// 	str = "&";
		// }
		
		// if(this.filterType == 'other' && this.reason){
		// 	filter += str+"reason="+this.reason;
		// 	str = "&";
		// }
		
		
		if(this.startDate){
			let sDate = new Date(this.startDate);
			var startDate = moment(sDate).startOf('day').toISOString(); 
			filter +=  str+"startDate="+startDate;
			str = "&";
		}else if(!this.startDate) {
			let sDate = new Date();
			var startDate = moment(sDate).startOf('day').toISOString(); 
			filter +=  str+"startDate="+startDate;
			str = "&";
		}
		
		if(this.endDate){
			let eDate = new Date(this.endDate);
			//var endDate = moment(new Date(this.endDate)).endOf('day').toISOString(); 
			var endDate = moment(eDate).endOf('day').toISOString();  
			filter += str+"endDate="+endDate;
			str = "&";
		}else if(!this.endDate) {
			let eDate = new Date();
			var endDate = moment(eDate).endOf('day').toISOString(); 
			filter +=  str+"endDate="+endDate;
			str = "&";
		}

		// if(this.selectCity){
		// 	console.log("test");
		// 	console.log(this.selectCity);
		// 	filter += str+"city="+this.selectCity;
		// 	str = "&";
		// }
		
		this.getRecordsByFilter(filter);
	}

	downloadFile(data: any) {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, "logs_"+this.changeDateFormat(new Date())+".csv");
	}
	
	getRecordsByFilter(filter){
		this.http.get(baseUrl+"api/v1/errorLog/filter?"+filter,{ headers: this.CommonService.getHeaders() }).subscribe(res=>{
			this.records = res['data'];
			this.dataFetched = true;
			if(this.records){
				var data = [];
					
					if(this.isCsv && this.records.length > 0)
					{
						var info = {};
						this.records.forEach((record, index) => {
							if(record){
								info = {
									"Ticket ID": record.ticketId || '--', 
									"Vehicle Number": record.vehicleNumber,
									"Log Time":`${this.changeDateFormat(record.createdAt)}, ${this.changeTimeFormat(record.createdAt)}`,
									"Driver Name":record.driverName || '--',
									"Log Message":record.errorMsg
								};
								data.push(info);
							}
						});
						
						this.downloadFile(data);
					}
					
				}
				this.isCsv = false;
			}, error => {
			this.CommonService.autoLogout(error);
		})
	}
	
	// viewDocument(a)
	// {
	// 	if(this.filterType == 'o2refill'  || this.filterType == 'other')
	// 	{
	// 		console.log(a.expenseDocumentList);
	// 		this.expenseDocumentList = [...new Set(a.expenseDocumentList)];
	// 	}
	// 	else if(this.filterType == 'fuelrefill'){
	// 		console.log(a);
	// 		this.expenseDocumentList = [...new Set(a)];
	// 	}
	// 	else if(this.filterType == 'breakdown')
	// 	{
	// 		this.expenseDocumentList = [...new Set(a.gstBillDocumentList)];
	// 	}
	// 	else if(this.filterType == 'driveroutstanding')
	// 	{
	// 		this.expenseDocumentList = [...new Set(a.outstandingAmountDocumentList)];
	// 	}
	// 	else if(this.filterType == 'grooming')
	// 	{
	// 		this.expenseDocumentList = [...new Set(a)];
	// 	}
		
		
	// 	//console.log(this.expenseDocumentList);
	// 	this.isDocument = true;
	// }
	
	// hideImagePopup()
	// {
	// 	this.isDocument = false;
	// }
	
  // getDriverStaff()
  // {
  //   this.loadingnow = true;
  //   this.rosterdetailsservice.getAllDriverlist().subscribe(data =>{
  //     this.listItems = data['data'];
  //     //console.log("listItemsDriver", this.listItems)
  //     this.loadingnow = false;
  //    }, error => {
	// 	this.CommonService.autoLogout(error);
	// });
  // }

  // edit(type,item)
  // {
  //   //console.log("edit",type,item)
  //   this.cm.setActive('editRecords',item);
  //   this.activeItm = item;
  //   //console.log("activeItm",this.activeItm)
  // }

  // delete(type,item)
  // {
  //   this.activeItm = item;
  //   //console.log("delete",type,item)
  //   this.confirmationDialogService.confirm('Please confirm', 'Do you really want to delete the record ?')
  //   .then((confirmed) => { console.log('User confirmed:', confirmed); } )
  //   .catch(() => console.log('User dismissed'));
  // }

}

