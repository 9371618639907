import { Directive, Input, HostBinding, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: 'img[default]',
  host: {
    '(error)':'updateUrl()',
    '(load)': 'load()',
    '[src]':'src'
   }
})
export class ImagePreloadDirective {
  @Input() src:string;
  @Input() default:string;

  constructor(private renderer: Renderer2, private hostElement: ElementRef) {
  }

  updateUrl() {
    this.src = this.default;
  }
  load(){
    this.renderer.addClass(this.hostElement.nativeElement, 'image-loaded');
  }
}
