import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { CardDetailsManagerService } from 'src/app/services/card-details-manager.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'problem',
  templateUrl: './problem.component.html',
  styleUrls: ['./problem.component.scss'],
})
export class ProblemComponent implements OnInit {
  @Input() type: string;
  @Input() data: any;
  selectedreason = null;
  trpstrtTime;
  constructor(
    public cdman: CardDetailsManagerService,
    public cs: CommonService
  ) {}

  ngOnInit(): void {}

  openDetailView() {
    if (this.type == 'fix' && this.selectedreason != null) {
      this.cdman.setActive('problemDetails', {
        type: this.type,
        obj: this.data,
        fixtext: this.selectedreason,
      });
    } else if (this.type == 'followup' && this.selectedreason != null) {
      this.cdman.setActive('problemDetails', {
        type: this.type,
        obj: this.data,
        fixtext: this.selectedreason,
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('SimpleChanges', this.data);
    this.selectedreason = this.data.breakDown.problem;
    this.trpstrtTime = moment(this.data.tripStartTime).format('hh:mmA');
  }
}
