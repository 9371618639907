import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { baseUrl, employeeUrl } from '../config/env-config';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  allMasters: any;
  // LocationUrl = 'https://employee-management.staging.gethelpnow.in/employeeLocationHistory/getEmployeeLocationByEmployeeId/00157'
  url = "https://employee-management.staging.gethelpnow.in/employee/getAllEmployee "
  // configURL = "http://DriverAppLB-587246265.ap-south-1.elb.amazonaws.com/api/v1/config/appConfig";
  // vendorurl = "http://DriverAppLB-587246265.ap-south-1.elb.amazonaws.com/api/v1/vendor/getAllVendors";
  // dashUpdateurl = "http://DriverAppLB-587246265.ap-south-1.elb.amazonaws.com/api/v2/dash/dashboardItems/update";
  commHeadValues = {
    dashCount: "",
    fixNeededCount: ""
  }

  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService,) {

  }

  getHeaders() {
    var token = "";
    if (localStorage.getItem('currentUser')) {
      token = JSON.parse(localStorage.getItem('currentUser'))['data']['token'];
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + token);
    console.log(token, "token");

    return headers;
  }

  autoLogout(error: any) {
    if (error.status == 401) {
      // alert('User not authenticated');
      this.toastr.warning('User not authenticated')
      localStorage.clear();
      this.router.navigate(['login']);
    }

  }
  //token

  getHeader() {
    var tokens = "";
    if (localStorage.getItem('currentUser')) {
      tokens = JSON.parse(localStorage.getItem('currentUser'))['data']['token'];
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + tokens);
    console.log(tokens, "tokens");

    return headers;
  }

  autoLogouts(error: any) {
    if (error.status == 401) {
      // alert('User not authenticated');
      this.toastr.warning('User not authenticated')
      localStorage.clear();
      this.router.navigate(['login']);
    }

  }

  getMasters() {
    this.http.get(baseUrl + "api/v1/config/appConfig", { headers: this.getHeaders() }).subscribe(res => {
      this.allMasters = res['data'];
      localStorage.setItem("masterdata", JSON.stringify(this.allMasters));
    }, error => {
      this.autoLogout(error);
    })
  }
  employees() {
    return this.http.get(employeeUrl + "employee/getAllEmployee", { headers: this.getHeaders() })
  }
  // locationByEmployeeId()
  // {
  //   return this.http.get('https://employee-management.staging.gethelpnow.in/employeeLocationHistory/getEmployeeLocationByEmployeeId/00157', { headers: this.getHeaders()})
  // }
  getEmployeeCurrentLocationById(id: string) {
    return this.http.get(`https://employee-management.staging.gethelpnow.in/employeeCurrentLocation/getEmployeeLocationByEmployeeId/${id}`, { headers: this.getHeaders() })
  }
  getEmployeePastLocationById(id: string) {
    return this.http.get(`https://employee-management.staging.gethelpnow.in/pastLocationTracking/getEmployeeLocationPastHistoryByEmployeeId/${id}`, { headers: this.getHeaders() })
  }
  getLocationHistoryById(id: string) {
    return this.http.get(`${employeeUrl}employeeLocationHistory/getEmployeeLocationByEmployeeId/${id}`, { headers: this.getHeaders() })
  }
  getAllMasters() {
    return this.http.get(baseUrl + "api/v1/config/appConfig", { headers: this.getHeaders() })
  }

  getAllVendors() {
    return this.http.get(baseUrl + "api/v1/vendor/getAllVendors", { headers: this.getHeaders() })
  }

  updateDashitm(data) {
    return this.http.post(baseUrl + "api/v2/dash/dashboardItems/update", data, { headers: this.getHeaders() })
  }

  timeformat(data, format) {
    return moment(data).format(format);
  }

  setCommValue(key, val) {
    this.commHeadValues[key] = val;
  }

  getCommValue(key) {
    return this.commHeadValues[key];
  }
  getEmployeePastLocationByID(id: string, data: any) {
    return this.http.post(`${employeeUrl}pastLocationTracking/getEmployeeLocationPastHistoryFromTo/${id}`, data, { headers: this.getHeaders() })
  }

}
