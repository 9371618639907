import { validator } from './../../../utils/validator';
import { Component, OnInit, Input, Output, EventEmitter, Injector, forwardRef } from '@angular/core';
import { AbstractControl, NgControl, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'app-timepicker',
  templateUrl: './app-timepicker.component.html',
  styleUrls: ['./app-timepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppTimepickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AppTimepickerComponent,
      multi: true
    }
  ]
})
export class AppTimepickerComponent implements OnInit {

  @Input() name: string;
  @Input() val: string;
  @Input() noGrid: boolean = false;

  @Input() label;
  @Input() labelSmall;

  @Input() errorMsg = "Invalid Input";
  @Input() errorHint;
  @Input() clearable = true;

  @Input() placeholder = "";

  @Input() min = null;
  @Input() max = null;

  @Input() srcImage = null;
  @Input() srcTitle = null;

  @Input() disabled = false;

  customValidators = new validator();

  inputType = "text";
  private _type;
  get type(): any {
    return this._type;

  }
  @Input() set type(val: any) {
    this._type = val;
    // this.inputType
  }

    // Both onChange and onTouched are functions
    onValChange: any = () => { };
    onValTouched: any = () => { };

    @Output()
    onBlur: EventEmitter<any> = new EventEmitter();

    @Output()
    onChange: EventEmitter<any> = new EventEmitter();

    @Output()
    onTouched: EventEmitter<any> = new EventEmitter();

    @Input() formControlName: string;
    public control: AbstractControl;

    get value() {
      return this.val;
    }

    set value(val) {
      this.val = val;
      this.onChange.emit(val);
      this.onValChange(val);
      this.onValTouched();
    }

  constructor(private injector: Injector) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.control = ngControl.control as FormControl;
        this.addValidators(this.type);
      });
    } else {
      // Component is missing form control binding
        // console.warn('Missing FormControlName directive from host element of the component');
    }
  }

  addValidators(type) {
    this.control.setValidators([this.control.validator]);
    this.control.updateValueAndValidity();
  }

  validate({ value }: FormControl) {
    return null;
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onValChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  //callback function passed by the forms API
  registerOnTouched(fn) {
    this.onValTouched = fn;
  }
  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    // if (value) {
      this.value = value;
    // }
  }

}
