import {
  Component,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { EmployeeDataService } from 'src/app/employeeServices/employee-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {} from 'googlemaps';
import { CommonService } from '../../services/common.service';
import * as moment from 'moment';
import Pusher from 'pusher-js';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { baseUrl, employeeUrl } from 'src/app/config/env-config';

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss'],
})
export class TrackComponent {
  employees: any;
  employeeHistory: any[];
  

  constructor(
    private employeeDataHistory: CommonService,
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router,
    public cs: CommonService,
  ) {
    employeeDataHistory.employees().subscribe((data: any) => {
      this.employees = data;
      this.employeeHistory = data.data;
    });
  }
  viewDocument(a: any) {
		this.router.navigate(['track'], { queryParams : {id: a.employeeId}});    
  }
  openDriverPopup(){
    this.router.navigate(['add-team']);    
  }
  terminateDriver(a:any){
    let empId = a.employeeId;
    let deleteDriver : any;
    if(a?.employeeType?.toLowerCase() == 'driver'){
      deleteDriver = `${baseUrl}api/v1/driver/delete/${empId}`;
    }else{
      deleteDriver = `${employeeUrl}employee/terminateEmployee/${empId}`;
    }
    this.http.delete(deleteDriver, {
      headers: this.cs.getHeaders(),
    })
    .subscribe(
      (res) => {
        var response = res['meta'];
        console.log(res, "ressss");
        if(response?.statusCode === 200){
          this.toastr.success("Terminated successfully");
          window.location.reload();
        }
      }
    )
  }
  resumeDriver(a:any){
    console.log(a.employeeType,"aa");
    let empId = a.employeeId;
    let deleteDriver : any;
    if(a?.employeeType?.toLowerCase() == 'driver'){
      deleteDriver = `${baseUrl}api/v1/driver/resume/terminatedDriver/${empId}`;
    }else{
      deleteDriver = `${employeeUrl}employee/resume/TerminatedEmployee/${empId}`;
    }
    this.http.get(deleteDriver, {
      headers: this.cs.getHeaders(),
    })
    .subscribe(
      (res) => {
        var response = res['meta'];
        console.log(res, "ressss");
        if(response?.statusCode === 200){
          this.toastr.success("Resume successfully");
          window.location.reload();
        }
      }
    )
  }
}
