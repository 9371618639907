import { Injectable } from "@angular/core";
import { Adapter } from "./adapter";

export class Vehicle {
    constructor(
       public id: String,
       public vehicleNumber: String,
       public vehicleType: String,
       public vehicleModel: String,
       public serviceYear: String,
       public partnerId: String,
       public ambulanceType: String,
       public vehicleCategory: String,
       public vehicleParkLocation: String,
       public vehicleLocationAddress: String,
       public vehicleLocationLongitude: String,
       public vehicleLocationLatitude: String,
       public createdAt: Date,
       public updatedAt: Date
    ) {}
  }

  @Injectable({
    providedIn: "root",
  })

  export class VehicleAdapter implements Adapter<Vehicle> {
    adapt(item: any): Vehicle {
      return new Vehicle(
          item.id,
          item.vehicleNumber,
          item.vehicleType,
          item.vehicleModel,
          item.serviceYear,
          item.partnerId,
          item.ambulanceType,
          item.vehicleCategory,
          item.vehicleParkLocation,
          item.vehicleLocationAddress,
          item.vehicleLocationLongitude,
          item.vehicleLocationLatitude,
          new Date(item.createdAt),
          new Date(item.updatedAt));
    }
}