import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { AuthServiceService } from '../../../services/auth-service.service';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/app/config/env-config';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header-tab',
  templateUrl: './header-tab.component.html',
  styleUrls: ['./header-tab.component.scss'],
})
export class HeaderTabComponent implements OnInit {
  usrname;
  summaryInfo: any = '';
  isSummary: any = false;
  todayDate = new Date();
  userrole: any = ''
  constructor(
    private http: HttpClient,
    private router: Router,
    public cs: CommonService,
    private authService: AuthServiceService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.usrname = localStorage.getItem('username');
    this.userrole = JSON.parse(localStorage.getItem('currentUser'))['data']['roles'].toString()
    this.getDashItemsList();
    this.getVehiclesSummary();
  }
  getVehiclesSummary() {
    this.http
      .get(baseUrl + 'api/v1/vehicle/getVehiclesSummary/helpnow', {
        headers: this.cs.getHeaders(),
      })
      .subscribe(
        (res) => {
          this.summaryInfo = res['data'];
        },
        (error) => {
          this.cs.autoLogout(error);
        }
      );
  }

  getDashItemsList() {
    this.http
      .get(
        baseUrl +
        'api/v1/VehicleActionHistory/getVehicleHistoryByVehicleID/helpnow',
        { headers: this.cs.getHeaders() }
      )
      .subscribe(
        (res) => {
          this.cs.setCommValue('dashCount', res['data'].length);
        },
        (error) => {
          this.cs.autoLogout(error);
        }
      );
  }

  summaryToggle() {
    if (this.isSummary) {
      this.isSummary = false;
    } else {
      this.isSummary = true;
    }
    this.getVehiclesSummary();
  }

  logout() {
    this.authService.logout();
    this.toastr.error('Logged out Successfully!');
    this.router.navigate(['login']);
  }
}
