import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { CardDetailsManagerService } from 'src/app/services/card-details-manager.service';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { baseUrl, fileUploadUrl } from 'src/app/config/env-config';


@Component({
  selector: 'problem-details',
  templateUrl: './problem-details.component.html',
  styleUrls: ['./problem-details.component.scss']
})
export class ProblemDetailsComponent implements OnInit {
  @Input() activated: boolean = false;
  @Output() modalClosed = new EventEmitter();
  mdata = {};
  type;
  showModal:boolean;
  selectedVendorID;
  vaddrs;
  phno;
  estcost;
  estTime;
  allVendors = [];
  prblmtxt;
  selVendor;
  trpstrttime;
  assignedBy;
  vdetails;
  timstmp;

  gstupobj;

  constructor(private http: HttpClient,public cdman:CardDetailsManagerService,public cs:CommonService) { }

  ngOnInit(): void {
    this.showModal = false;
  }

  closeCard()
  {
    this.cdman.dismiss('problemDetails');
    this.selectedVendorID = undefined;
    this.vaddrs = undefined;
    this.phno = undefined;
    this.estcost = undefined;
    this.estTime = undefined;
    this.allVendors = [];
    this.type = undefined;
    this.selVendor = undefined;
    this.valueChanged();
  }


  ngOnChanges(changes: SimpleChanges) {
        
    
    if(this.activated)
    {
      
      console.log("it changeddd",this.activated,this.cdman.getModalData())
      this.timstmp = this.cdman.getModalData()['obj']['tripStartTime']
      this.type = this.cdman.getModalData()['type'];
      if(this.type == 'fix')
      {
        this.prblmtxt =  this.cdman.getModalData()['fixtext'];
      }
      else
      if(this.type == 'followup')
      {
        this.prblmtxt = this.cdman.getModalData()['obj']['breakDown']['problem'];
        this.selVendor = this.cdman.getModalData()['obj']['breakDown']['vendorDetails'];
        this.selectedVendorID = this.selVendor['id'];
        this.vaddrs = this.selVendor['vendorAddress'];
        this.phno = this.selVendor['vendorPhoneNumber'];
        this.estcost =  this.selVendor['vendorCostEstimation'];
        this.estTime =  this.selVendor['vendorTimeEstimation'];
        this.trpstrttime = moment(this.cdman.getModalData()['obj'].tripStartTime).format('hh:mmA');
        this.assignedBy = this.cdman.getModalData()['obj']['assignedByName'];
        this.vdetails = this.cdman.getModalData()['obj']['vehicleDetails']

      }
 
      this.cs.getAllVendors().subscribe(res=>{
        this.allVendors = res['data'];
        console.log(this.allVendors,"allVendors")
      })

    }
    
}

onChangeSelect(e)
{
  console.log("selectedVendor",this.selectedVendorID)
  if(this.type == 'fix')
  {
    this.selVendor = this.allVendors.filter(v=> v.id == this.selectedVendorID)[0];
    this.vaddrs =   this.selVendor['vendorAddress'];
    this.phno =     this.selVendor['vendorPhoneNumber'];
    this.estcost =  this.selVendor['vendorCostEstimation'];
    this.estTime =  this.selVendor['vendorTimeEstimation'];
    
  }

  
}


valueChanged() { 
          this.modalClosed.emit({});
      }

  fixSubmit()
  {
    let body = this.cdman.getModalData()['obj'];
    body.breakDown.problem = this.prblmtxt;
    body.breakDown.vendorDetails = this.selVendor;
    body.breakDown.vendorDetails.vendorAddress = this.vaddrs;
    body.breakDown.vendorDetails.vendorPhoneNumber = this.phno;
    body.breakDown.vendorDetails.vendorCostEstimation = this.estcost;
    body.breakDown.vendorDetails.vendorTimeEstimation = this.estTime;

      this.cs.updateDashitm(body).subscribe(val=>{
          this.closeCard();
      })
  }


  fileChange(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        let formData:FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('serviceName','driverAPP');
        formData.append('userID','1');
        // fetch("http://13.127.191.235:3000/api/v1/file/upload", {
        //file upload
            fetch(fileUploadUrl, {
              method: 'POST',
              body: formData
            }).then((response: any) => {
              let res = response.json()
              if(response.status == 200 || response.status ==  201)
              {
                res.then(a=>{
                  console.log('success',a)
                  this.createDoc('partpics',a.data.contentType,a.data.url);
                })
              }
              else
              {
                res.then(a=>{
                  console.log('error',a.meta.msg)
                  alert(a.meta.msg)
                })
              }
              
            })
            
    }
}

upGST(event)
{
  let fileList: FileList = event.target.files;
  if(fileList.length > 0) {
      let file: File = fileList[0];
      let formData:FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('serviceName','driverAPP');
      formData.append('userID','1');
      //file upload url
      this.gstupobj = {
        // url:"http://13.127.191.235:3000/api/v1/file/upload",
        url: fileUploadUrl,
        body:{
          method: 'POST',
          body: formData
        }
      }


          
  }
}


makeGstCall()
{
  if(this.gstupobj)
  {
    fetch(this.gstupobj.url, this.gstupobj.body).then((response: any) => {
      let res = response.json()
      if(response.status == 200 || response.status ==  201)
      {
        res.then(a=>{
          console.log('success',a)
          this.createDoc('gstbill',a.data.contentType,a.data.url);
        })
      }
      else
      {
        res.then(a=>{
          console.log('error',a)
          alert(a.meta.msg)
        })
      }
      
    })
  }
}


createDoc(type,mimeType,docURL)
{

  this.http.post(baseUrl+"api/v1/documentDetails/create",{
    "name": type,
    "mimeType": mimeType,
    "type": type,
    "s3id": null,
    "documentType": null,
    "driverActionsId": null,
    "documentURL": docURL,
    "userId": this.cdman.getModalData()['obj']['driverDetails']['id']
}).subscribe(res=>{
  console.log(res,"docuppp")
  alert("Uploaded Successfully");


})

}


showimgs()
{
  let unique = [...new Set(this.cdman.getModalData()['obj']['tripDocumentList'])];
  console.log(unique)

  unique.forEach(u=>{
    // window.open(
      // u,
      // '_blank' 
    // );
  })
  
}



}
