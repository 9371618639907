import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { AuthServiceService } from '../services/auth-service.service';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/app/config/env-config';
@Component({
  selector: 'app-download-header',
  templateUrl: './download-header.component.html',
  styleUrls: ['./download-header.component.scss']
})
export class DownloadHeaderComponent implements OnInit {
  usrname;
  summaryInfo:any="";
  isSummary:any= false;
  todayDate = new Date();
  constructor(private http: HttpClient,private router: Router,public cs:CommonService,private authService: AuthServiceService,) { }

  ngOnInit(): void {
    this.usrname =localStorage.getItem('username');
	this.getDashItemsList();
	this.getVehiclesSummary();
  }
    getVehiclesSummary() {
		 this.http.get(baseUrl+"api/v1/vehicle/getVehiclesSummary/helpnow",{ headers: this.cs.getHeaders() }).subscribe(res=>{
		   this.summaryInfo = res['data'];
		}, error => {
			this.cs.autoLogout(error);
		})
	}
	
	getDashItemsList() {
		 this.http.get(baseUrl+"api/v1/VehicleActionHistory/getVehicleHistoryByVehicleID/helpnow",{ headers: this.cs.getHeaders() }).subscribe(res=>{
		   this.cs.setCommValue('dashCount',res['data'].length);
		}, error => {
			this.cs.autoLogout(error);
		})
	}
	
	summaryToggle()
	{
		if(this.isSummary)
		{
			this.isSummary = false;
		}
		else{
			this.isSummary = true;
		}
		this.getVehiclesSummary();
	}
	
	
	logout() {     
		this.authService.logout();
		this.router.navigate(['login']);
	 
	} 
  // constructor() { }

  // ngOnInit(): void {
  // }

}
