import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { authUrl } from '../config/env-config';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  // private apiurl = "https://auth-service.prod.gethelpnow.in/api/v1/auth/_/username/login";
  //login url
  private apiurl = authUrl;

  constructor(private http: HttpClient) { }

  login(userName:String,password:String)
  {
    return this.http.post(this.apiurl,{userName,password});
  }
  logout() {  
    localStorage.removeItem('currentUser');   
    localStorage.removeItem('username');
  }  
  public get loggedIn(): boolean {  
    return (localStorage.getItem('currentUser') !== null);  
  } 
}
