import { FormControl, ValidatorFn, FormGroup } from "@angular/forms";
import * as moment from 'moment';

export class validator {

  constructor() {

  }

  validateAddress(control: any) {
    if (!control.value) {
      return null;
    }
    if (!control.value || !control.value.match(/^[a-zA-Z0-9, /.-]*$/)) {
      return {
        validateAddress: true
      };
    }
  }

  minLength(length: number): ValidatorFn {
    return (control: FormControl) => {
      if (!control.value || typeof control.value === 'string' && control.value.trim().length < length) {
        return {
          minlength: true
        };
      }

      return null;
    };
  }

  maxLength(length: number): ValidatorFn {
    return (control: FormControl) => {
      if (control.value && typeof control.value === 'string' && control.value.trim().length > length) {
        return {
          maxlength: true
        };
      }

      return null;
    };
  }

  trimValidation(control: any) {
    if (!control.value) {
      return null;
    }

    return (control.value.trim() !== '') ? null : {
      validTrimmedString: {
        invalid: true
      }
    };
  }

  validateChipString(control: FormControl) {
    // console.log(control.value, control.value.match(/^[a-zA-Z ]*$/));

    return new Promise(resolve => {
      if (!control.value || !control.value.match(/^[a-zA-Z ]*$/)) {
        resolve({
          'invalidString': true
        });
      } else {
        resolve(null);
      }
    })



    // return null;
  }

  validStringWithNumber(control: any) {

    if (!control.value) {
      return null;
    }

    return (control.value && control.value.match(/^[a-zA-Z0-9 ]*$/)) ? null : {
      validStringWithNumber: {
        invalid: true
      }
    };
  }

  validStringNumber(control: any) {

    if (!control.value) {
      return null;
    }
    return (control.value && control.value.match(/^\d+(\.\d+)?$/)) ? null : {
      validStringNumber: {
        invalid: true
      }
    };
  }

  validStringPositiveNumber(control: any) {

    if (!control.value) {
      return null;
    }

    return (control.value && control.value.toString().match(/^\d+(\.\d+)?$/) && parseInt(control.value) >= 0) ? null : {
      validStringPositiveNumber: {
        invalid: true
      }
    };
  }

  validStringWithPeriod(control: any) {

    if (!control.value) {
      return null;
    }

    return (control.value && control.value.match(/^[a-zA-Z. ]*$/)) ? null : {
      validString: {
        invalid: true
      }
    };
  }

  checkSpaces(control: any) {
    let noMoreSpaces = true
    if (!control.value) {
      return null;
    }
    if (control.value && control.value.split(' ').length > 2) {
      noMoreSpaces = false;
    } else {
      noMoreSpaces = true;
    }
    return (control.value && noMoreSpaces) ? null : {
      checkSpaces: {
        invalid: true
      }
    };
  }

  validStringWithNumberPeriodAndHifen(control: any) {

    if (!control.value) {
      return null;
    }

    return (control.value && control.value.match(/^[a-zA-Z0-9-. ]*$/)) ? null : {
      validStringWithNumberPeriodAndHifen: {
        invalid: true
      }
    };
  }

  validStringWithHifen(control: any) {

    if (!control.value) {
      return null;
    }

    return (control.value && control.value.match(/^[a-zA-Z-. ]*$/)) ? null : {
      validString: {
        invalid: true
      }
    };
  }

  validString(control: any) {

    if (!control.value) {
      return null;
    }

    return (control.value && control.value.match(/^[a-zA-Z ]*$/)) ? null : {
      validString: {
        invalid: true
      }
    };
  }

  noConsecucutiveSpaces(control: any) {
    if (!control.value) {
      return null;
    }

    return (control.value && !control.value.match(/\s\s/)) ? null : {
      noConsecucutiveSpaces: {
        invalid: true
      }
    };
  }

  confirmPassword(c: FormControl): any {
    if (c.get('password').value !== c.get('confirm_password').value) {
      return { invalid: true };
    }
    return null;
  }

  validNumberWithSpaces(control: any) {
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^[0-9 ]+$/);
    return exp ? null : {
      validNumberWithSpaces: {
        invalid: true
      }
    };
  }

  validMobile(control: any) {
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^[0-9]{4,13}$/);
    return exp ? null : {
      validMobile: {
        invalid: true
      }
    };
  }

  validTagsWithNoSpecialChar(control: any) {
    if (!control.value) {
      return null;
    }
    let exp = control.value.filter((val, i)=>{
        if (typeof val == "string") {
          return val.match(/[^A-Za-z0-9]+/) ;
        } else {
          return true;
        }
      }).length;
    return !exp ? null : {
      validTagsWithNoSpecialChar: {
        invalid: true
      }
    };
  }

  validOtp(control: any) {
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^[0-9]{6,6}$/);
    return exp ? null : {
      validOtp: {
        invalid: true
      }
    };
  }

  validTagLength(control: any) {
    if (!control.value) {
      return null;
    }
    let length = control.value.length - 1;
    if (control.value && control.value.length) {
      for (let i = 0; i <= control.value.length - 1; i++) {
        if (control.value[i].length <= 30 && length < 10) {
          if ((length == i)) {
            return null;
          }
        } else {
          return {
            validTagLength: {
              invalid: true
            }
          }
        }
      }
    }

  }

  validPinCode(control: any) {
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^[a-zA-Z0-9 -]{6}$/);
    return exp ? null : {
      validPinCode: {
        invalid: true
      }
    };
  }

  validPassword(control: any) {
    if (!control.value) {
      return null;
    }
    let value: string = control.value;

    return (value && value.match(/^[^-\s][a-zA-Z0-9!@#$&()\\-`.+,/\ "]*$/)) ? null : {
      validPassword: {
        invalid: true
      }
    };
  }

  validLatitudeLongitude(control: any) {
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^-?[0-9]\d*(\.\d+)?$/);
    // (/^-?[0-9]\d*(\.\d+)?$/);
    return exp ? null : {
      validLatitudeLongitude: {
        invalid: true
      }
    };
  }

  numberWithDecimal(control: any) {
    // /^\d*\.?\d*$/
    if (!control.value || typeof control.value != 'string') {
      return null;
    }
    let exp = control.value && control.value.match(/^\d*\.?\d*$/);
    return exp ? null : {
      numberWithDecimal: {
        invalid: true
      }
    };
  }

  validateDateRange(value: any, min, max) {
    if (!value) {
      return false;
    }

    if (min && max) {
      return moment(value).isBetween(min, max);
    } else if (min) {
      return moment(value).isSameOrAfter(min);
    } else if (max) {
      return moment(value).isSameOrAfter(max);
    }
  }

  validateDOB(control: any) {
    if (!control.value) {
      return null;
    }
    // console.log(control.value, moment(control.value).isValid(), !isNaN(control.value),  moment(control.value).isAfter(moment().subtract(18, 'years')))
    return (control.value && moment(control.value).isValid() && !isNaN(control.value) && !moment(control.value).isAfter(moment().subtract(18, 'years'))) ? null : {
      validateDOB: {
        invalid: true
      }
    };
  }

  validDate(control: any) {
    if (!control.value) {
      return null;
    }

    return (control.value && moment(control.value).isValid() && !isNaN(control.value)) ? null : {
      validDate: {
        invalid: true
      }
    };
  }

  validateEmail(control: any) {
    if (control.value && control.value.address) {

      return control.value.address.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? null : {
        validateEmail: {
          invalid: true
        }
      };
    } else if (control.value && control.value.match) {
      return control.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? null : {
        validateEmail: {
          invalid: true
        }
      };
    } else {
      return null;
    }
  }

  validateEmailString(str: any) {
    return str.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  }

  validateWebsite(control: any) {
    if (!control.value) {
      return null;
    }
    return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/i.test(control.value) ? null : {
      validateWebsite: {
        invalid: true
      }
    };

  }

  validInteger(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^\-?[0-9]*$/);
    return exp ? null : {
      validInteger: {
        invalid: true
      }
    }
  }

  validFloat(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^\-?[0-9]*\.?[0-9]*$/);
    return exp ? null : {
      validFloat: {
        invalid: true
      }
    }
  }

  validStringWithSpecialCharacters(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^[a-zA-Z0-9 !@#£€¥\$\?%\^\&*\)\(\.\]\[,:;°_-]+$/);
    return exp ? null : {
      validStringWithSpecialCharacters: {
        invalid: true
      }
    }
  }

  onlyAlphabets(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^[a-zA-Z ]*$/);
    return exp ? null : {
      onlyAlphabets: {
        invalid: true
      }
    }
  }

  validCity(control: any) {
    if (!control.value || typeof control.value != "string") {
      return null;
    }
    let exp = control.value && control.value.match(/^[a-zA-Z0-9 \\-\\.\\'\\,]$/);
    return exp ? null : {
      validCity: {
        invalid: true
      }
    }
  }
}
