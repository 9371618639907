import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Vehicle, VehicleAdapter } from "../models/vehicle.model";
import{baseUrl} from'../config/env-config';
import { CommonService } from 'src/app/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class RosterDetailsService {

  constructor(private http: HttpClient,private adapter: VehicleAdapter, public commonService : CommonService) {
   }
   
 getAllVehicles(){
   return this.http.get(baseUrl+"api/v1/vehicle/getAllVehicles", { headers: this.commonService.getHeaders() });
 }
 getVehiclesListByCity(filter:String){
   return this.http.get(baseUrl+"api/v1/vehicle/getVehicleByCity/"+filter, { headers: this.commonService.getHeaders() });
 }
 
 getAllHelpNowVehicles(){
   return this.http.get(baseUrl+"api/v1/vehicle/getVehicleByType/helpnow", { headers: this.commonService.getHeaders() });
 }

 getAllDriverlist(){
  return this.http.get(baseUrl+"api/v1/driver/getAllDrivers", { headers: this.commonService.getHeaders() });
 }

 getVehicleDetailsByID(vehicleID:String)
 {
  return this.http.get(baseUrl+"api/v2/roaster/roasterItems/"+vehicleID, { headers: this.commonService.getHeaders() });
 }
 getAllHelpNowVehicleStatus(){
  return this.http.get(baseUrl+"api/v1/vehicle/getVehicleByType/helpnow?active=true", { headers: this.commonService.getHeaders() });
}
}
