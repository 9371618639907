import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { leadserviceurl } from "src/app/config/env-config";
import { CommonService } from "src/app/services/common.service";



@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
})

export class NotesComponent implements OnInit {
    FormData: {
        title: string;
        message: string;
    } = {
            title: '',
            message: ''
        };
    validation: {
        title: boolean;
        message: boolean;
    } = {
            title: false,
            message: false
        };
    username: any = localStorage.getItem('username');
    userId: any = JSON.parse(localStorage.getItem('currentUser'))['data']['userID'];
    token: any = JSON.parse(localStorage.getItem('currentUser'))['data']['token']
    NotesList: [] = []
    updateNote: boolean = false
    selectedNote: any = '';
    notebuttondisable: boolean = false;


    constructor(
        private router: Router,
        private http: HttpClient,
        public cs: CommonService,
        private toastr: ToastrService,
    ) { }
    ngOnInit(): void {
        this.getnoteslist()
    }

    getnoteslist() {
        this.http.get(leadserviceurl + 'notes/getAllNotes', { params: { accessType: 'driver' }, headers: this.cs.getHeaders() })
            .subscribe((res) => {
                // console.log(res['data'], "getdata");
                this.NotesList = res['data']
            },
                (error) => {
                    console.log(error.error, "ll");
                    this.toastr.error("Something went wrong !!!");
                }
            )
    }

    validatetitle(title: any) {
        if (title) {
            if (title.length <= 100) {
                this.validation.title = true;
            } else {
                this.validation.title = false;
                this.toastr.error("Title must be under 100 characters");
            }
        } else {
            this.validation.title = false;
            this.toastr.error("Title is required");
        }
    }
    validatemessage(message: any) {
        if (message) {
            if (message.length <= 800) {
                this.validation.message = true;
            } else {
                this.validation.message = false;
                this.toastr.error("Message must be under 800 characters");
            }
        } else {
            this.validation.message = false;
            this.toastr.error("Message is required");
        }
    }

    addNotes() {
        const headers = new HttpHeaders({
            'Authorization': `Bearer ` + this.token, // Replace with your actual authentication token
            'Content-Type': 'application/json' // Set the content type as needed
        });
        this.notebuttondisable = true
        this.http
            .post(leadserviceurl + 'notes/createNote', {
                createdById: this.userId,
                createdByName: this.username,
                title: this.FormData.title,
                message: this.FormData.message,
                status: true,
                fromDate: null,
                toDate: null,
                oneTimeShow: false,
                type: 'driver'
            },
                { headers: headers }
            ).subscribe(
                (res) => {
                    // console.log(res, "ohhoho");
                    this.getnoteslist()
                    this.FormData.message = ''
                    this.FormData.title = ''
                    this.toastr.success('Note created successfully')
                    this.notebuttondisable = false
                },
                (error) => {
                    console.log(error);
                    this.toastr.error('Something went wrong !!!')
                    this.notebuttondisable = false

                })
        // console.log('validation true', this.userId, this.username, this.FormData.title, "title", this.FormData.message, "message");
    }

    UpdateNote() {
        // console.log(this.FormData.message, this.FormData.title, this.selectedNote, 'Update');
        const headers = new HttpHeaders({
            'Authorization': `Bearer ` + this.token, // Replace with your actual authentication token
            'Content-Type': 'application/json' // Set the content type as needed
        });
        this.notebuttondisable = true
        this.http
            .post(leadserviceurl + 'notes/updateNotes/' + this.selectedNote.id, {
                createdById: this.userId,
                createdByName: this.username,
                title: this.FormData.title,
                message: this.FormData.message,
                status: this.selectedNote.status,
                fromDate: null,
                toDate: null,
                oneTimeShow: false,
                type: 'driver'
            },
                { headers: headers }
            ).subscribe(
                (res) => {
                    // console.log(res, "ohhohoupdated");
                    this.getnoteslist()
                    this.FormData.message = ''
                    this.FormData.title = ''
                    this.selectedNote = ''
                    this.updateNote = false
                    this.toastr.success('Note updated successfully')
                    this.notebuttondisable = false

                },
                (error) => {
                    console.log(error);
                    this.toastr.error('Something went wrong !!!')
                    this.notebuttondisable = false

                })
    }

    createnote() {
        if (this.FormData.title && this.FormData.message && this.updateNote) {
            this.validatetitle(this.FormData.title)
            this.validatemessage(this.FormData.message)
            if (this.validation.title && this.validation.message && this.updateNote) {
                this.UpdateNote()
            }
        }
        else if (this.FormData.title && this.FormData.message) {
            this.validatetitle(this.FormData.title)
            this.validatemessage(this.FormData.message)
            if (this.validation.title && this.validation.message) {
                this.addNotes()
            }
        } else {
            this.toastr.error("Please fill all the details");
        }
    }

    onStatuschange(note: any) {
        // console.log(note, "notesdata");
        this.http.get(leadserviceurl + 'notes/activeDeactiveNotes/' + note.id + '?status=' + note.status, { headers: this.cs.getHeaders() })
            .subscribe(
                (res) => {
                    this.toastr.success('Note status changed successfully')
                    // console.log(res, "ohhoho")/
                },
                (error) => {
                    console.log(error);
                    this.toastr.error('Something went wrong !!!')
                }
            )
    }

    onEditClick(note: any) {
        // console.log(note, "editclick");
        this.FormData.title = note.title
        this.FormData.message = note.message
        this.updateNote = true
        this.selectedNote = note
    }
    resetform() {
        this.updateNote = false
    }
}