import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any[], ...args: string[]): unknown {
    return value.filter((item) => item && JSON.stringify(item).toLowerCase().includes(args[0].toLowerCase()));
  }

}
