// export const baseUrl =  'http://driver-service-924473473.ap-south-1.elb.amazonaws.com/api/v1/';

// export const baseUrl =  'http://ec2-3-109-56-160.ap-south-1.compute.amazonaws.com:8080/';
import { environment } from '../../environments/environment';


// export const baseUrl =  'https://driver-backend.prod.gethelpnow.in/';
// export const authUrl = 'https://auth-service.prod.gethelpnow.in/api/v1/auth/_/username/login'
// export const fileUploadUrl = 'https://file-service.prod.gethelpnow.in/api/v1/file/upload'

export const baseUrl =  environment.DRIVER_BACKEND_URL;
export const authUrl = environment.AUTH_URL + '?serviceType=driver_app';
export const fileUploadUrl = environment.FILE_UPLOAD_URL;
export const employeeUrl = environment.EMPLOYEE_BACKEND_URL;
export const leadserviceurl = environment.LEAD_SERVICE_URL;
